import { useTheme } from '@mui/material/styles'
import { TooltipRenderProps } from 'react-joyride'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { Button, Typography } from '@blockpass-org/ui'
import IconButton from '@mui/material/IconButton'

export const TutorialTooltip = (props: TooltipRenderProps) => {
  const theme = useTheme()
  const {
    backProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
    size,
  } = props

  const styles = {
    primaryButton: {
      boxShadow: 'none',
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: '#e8f0ff',
      },
    },
  }

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        bgcolor: theme.palette.primary.main,
        color: 'white',
        borderRadius: 2,
        width: 320,
        p: 2,
      }}
      className="tooltip__body"
      {...tooltipProps}
    >
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
        {step.title && (
          <Typography color="white" fontWeight={500} variant="subtitle1">
            {step.title}
          </Typography>
        )}
        {step.hideCloseButton ? null : (
          <IconButton size="small" sx={{ p: '6px', mr: '-6px' }} {...skipProps}>
            <CloseIcon sx={{ fontSize: '16px', color: 'white' }} />
          </IconButton>
        )}
      </Box>
      {/* Body */}
      <Box mt={1}>
        <Typography variant="body2" color="white">
          {step.content}
        </Typography>
      </Box>
      {/* Footer */}
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Skip */}
        {size > 1 ? (
          <Box>
            <Typography variant="caption" color="white" whiteSpace={'nowrap'}>
              {index + 1} of {size}
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 1,
            justifyContent: 'flex-end',
          }}
        >
          {index > 0 && (
            <Button
              size="small"
              disableElevation
              // sx={styles.button}
              {...backProps}
            >
              {backProps.title}
            </Button>
          )}
          {continuous && (
            <Button
              size="small"
              disableElevation
              sx={styles.primaryButton}
              {...primaryProps}
            >
              {primaryProps.title}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
