import Footer from '@/layout/Footer/Footer'
import BannerProvider from '@/providers/UIBannerProvider'
import Box from '@mui/material/Box'

export const ServiceListLayout = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
        margin: 4,
      }}
    >
      <BannerProvider />
      {children}
      <Footer />
    </Box>
  )
}
