/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "Вы можете запросить дополнительную информацию у своих пользователей во время регистрации. Эти атрибуты не проверяются Blockpass.",
  "txt2FA": "2ФА",
  "txt404": "Страница, которую вы ищете, не найдена!",
  "txtAML": "ПОД",
  "txtAMLCheck": "проверка ПОД",
  "txtAMLDesc1": "Проведите проверку по глобальной базе данных списков политически значимых лиц/санкционированных лиц и негативных СМИ, а также определите, являются ли ваши клиенты политически незащищенными, подвергнуты санкциям или имеют ли они неблагоприятный профиль в СМИ.",
  "txtAMLDesc2": "Эта проверка требует от пользователей указания своего полного имени и даты рождения.",
  "txtAPIAndWebhooks": "API и вебхуки",
  "txtAccountDeletion": "Удаление аккаунта",
  "txtAccountDeletionDesc": "Удаление мобильных и веб-аккаунтов",
  "txtAction": "Действие",
  "txtActive": "Активный",
  "txtActivityLogs": "Журналы активности",
  "txtAdd": "Добавлять",
  "txtAddCompany": "Добавить компанию",
  "txtAddMember": "Добавить участника",
  "txtAddMemberDesc": "Участник получит электронное письмо, предоставляющее доступ к этой услуге.",
  "txtAddMemberEmailIsRequired": "Пожалуйста, введите адрес электронной почты вашего участника.",
  "txtAddMembers": "Добавить участников",
  "txtAddNewBpOperatorToService": "Назначать",
  "txtAddNewCompany": "Добавить новую компанию",
  "txtAddNewOperatorSuccess": "Добавлен новый оператор успешно.",
  "txtAddNewsContent": "Добавить новость",
  "txtAddNotification": "Добавить уведомление",
  "txtAddOperatorToService": "Добавить оператора в сервис",
  "txtAddPaymentMethod": "Добавить способ оплаты",
  "txtAddPaymentMethodFail": "Здесь что-то не так. Пожалуйста, добавьте способ оплаты позже.",
  "txtAddPaymentMethodSuccessfully": "Ваша платежная карта успешно добавлена",
  "txtAddRichContent": "Добавьте богатый контент",
  "txtAddTag": "Добавить тег",
  "txtAddTagPlaceholder": "Введите метку тега...",
  "txtAddTagSuccess": "Тег «<0>» успешно добавлен.",
  "txtAddingTag": "Добавление тега ",
  "txtAdmin": "Админ",
  "txtAdminConsole": "Консоль администратора",
  "txtAdminSettingsUpdateSuccess": "Настройки администратора успешно обновлены.",
  "txtAdministration": "Администрация",
  "txtAdvanced": "Передовой",
  "txtAdvancedApprovalDesc": "Расширенный бот KYC: ",
  "txtAdvancedApprovalDesc1": "Набор из более чем 10 правил для автоматического одобрения или отклонения пользователей.",
  "txtAdvancedApprovalTooltip": "Расширенный бот KYC недоступен для этого плана. Свяжитесь с нами, если вы хотите включить его.",
  "txtAfterReturn": "после того, как API вернул данные ",
  "txtAgeRestriction": "Возрастное ограничение",
  "txtAgeRestrictionDesc1": "Вы можете исключить кандидатов на основании их возраста. ",
  "txtAgeRestrictionDesc2": "Кандидат должен быть как минимум",
  "txtAgeRestrictionDesc3": "лет.",
  "txtAllPlanFeatureHeader": "Все планы включают следующие функции",
  "txtAllTimeAreUTC": "(Все время указано в формате UTC)",
  "txtAllowed": "Допустимый",
  "txtAllowedCountries": "разрешенные страны",
  "txtAlreadyMember": "Этот пользователь уже является участником этой службы.",
  "txtAnnouncements": "Объявления",
  "txtAnyoneWithTheLink": "Любой, у кого есть ссылка",
  "txtAnyoneWithTheLinkDesc1": "Любой, у кого есть эта ссылка, может зарегистрироваться в вашем сервисе.",
  "txtAnyoneWithTheLinkDesc2": "Эта страница позволяет заявителям отправлять информацию KYC на вашу панель управления. Скопируйте и вставьте ссылку в электронное письмо или в любое место, где вы хотите ею поделиться.",
  "txtApiKey": "API-ключ",
  "txtApiKeyCopied": "Ваш ключ API скопирован",
  "txtApiKeyDeleteDesc": "После удаления ключ API невозможно восстановить. Если вы не совсем уверены, что ключ можно удалить, вместо этого вы можете установить статус неактивный.",
  "txtApiKeys": "API-ключи",
  "txtApply": " применять.",
  "txtApproveAdvanced": "Расширенный бот KYC",
  "txtApproveBasic": "KYC-бот",
  "txtApproveDesc1": "Вы можете сэкономить время, выбрав режим просмотра. \n",
  "txtApproveDesc2": "Боты KYC рекомендуются для большого количества пользователей. Видеть ",
  "txtApproveDesc3": "подробнее",
  "txtApproveDesc4": " в документации",
  "txtApproveFreeManual": "Вручную: одобрите или отклоните каждого пользователя вручную. Выберите план, чтобы ускорить процесс адаптации.",
  "txtApproveNone": "Ручная проверка",
  "txtApproverTooltipkyc": "Может просмотреть и одобрить KYC. Невозможно удалить профили или обновить настройки.",
  "txtApproverkyc": "Утверждающий KYC",
  "txtAssignCompanyToService": "Добавить информацию о компании",
  "txtAssignRole": "Назначенная роль",
  "txtAssignUserRole": "Назначенная роль",
  "txtAttributeDataDesc": "После архивирования профиля остаются только метаданные, атрибуты идентификации удаляются.",
  "txtAttributesConfig": "Конфигурация атрибутов",
  "txtAttributesConfigDesc": "Настройте атрибуты, доступные в профилях пользователей.",
  "txtAttributesGroupConfig": "Конфигурация группы атрибутов",
  "txtAttributesGroupConfigDesc": "Настройте группу атрибутов, доступных в профилях пользователей.",
  "txtAutoApproval": "Автоматическое одобрение",
  "txtAutoArchive": "Автоматическое архивирование профиля пользователя",
  "txtAutoArchiveTooltip": "Автоматически архивировать профиль пользователя @@ar_ch_ti_me@@ через несколько секунд после того, как API вернет данные.",
  "txtAutoTranslate": "(автоперевод)",
  "txtAutomationRules": "Правила автоматизации",
  "txtAvatar": "Аватар",
  "txtAvaxAddress": "Адрес лавины",
  "txtBack": "Назад",
  "txtBasicApprovalDesc": "KYC-бот:  ",
  "txtBasicApprovalDesc1": "Автоматическое одобрение пользователей с четкими проверками.",
  "txtBasicInfo": "Основная информация",
  "txtBestPractices": "лучшие практики",
  "txtBilling": "Биллинг",
  "txtBillingInfo": "Платежная информация",
  "txtBillingInfomation": "Платежная информация",
  "txtBillingOwner": "Владелец платежных данных",
  "txtBinanceAddress": "Адрес Binance (BSC)",
  "txtBitcoinAddress": "Биткойн-адрес",
  "txtBlockpass": "Blockpass",
  "txtBlockpassAccess": "Блокпасс доступ",
  "txtBlockpassAccessDesc": "Разрешите команде поддержки Blockpass доступ к вашей панели KYC.",
  "txtBlockpassAccessPermission": "У вас нет разрешения на доступ к этой функции",
  "txtBlockpassKycRules": "Правила KYC для Blockpass",
  "txtBpKYCRules": "Правила KYC для Blockpass",
  "txtBrowse": "Просматривать",
  "txtBtcAdress": "Биткойн-адрес",
  "txtBusinessInfomation": "Деловая информация",
  "txtBusinessTerms": "Деловые условия",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "Невозможно установить теги onchainKyc для сервисов, имеющих несколько криптографических полей.",
  "txtCancel": "Отмена",
  "txtCardHolderName": "Имя владельца карты",
  "txtCardInfomation": "Информация о карте",
  "txtCardanoAddress": "Кардано (ADA) адрес",
  "txtCaution": "Осторожность !",
  "txtCautionDesc1": "Если вы в настоящее время осуществляете регистрацию пользователей, не рекомендуется менять план. Пожалуйста, свяжитесь с нами для помощи.",
  "txtCautionDesc2": "В противном случае вы можете продолжить настройку.",
  "txtCertificatesOrderConfig": "Конфигурация заказа сертификатов",
  "txtCertificatesOrderConfigDesc": "Настроить порядок сертификатов в профилях пользователей",
  "txtChangeBillingPlan": "Изменить тарифный план",
  "txtChangePlan": "Изменить план",
  "txtChangeWebhook": "Обновлен вебхук",
  "txtChartEmptyTooltip": "Эта диаграмма будет заполняться, когда пользователи будут использовать ваши услуги.",
  "txtCheckDebugger": "Проверьте отладчик",
  "txtCheckDebuggerDesc": "Поиск чеков по идентификатору чека. Чек обычно выдает сертификат",
  "txtChecksConfig": "Проверяет конфигурацию",
  "txtChecksConfigDesc": "Настройте атрибуты и сертификат, определяющие проверку",
  "txtChinese": "китайский",
  "txtClickOrDragToUpload": "Нажмите или перетащите, чтобы загрузить свой логотип",
  "txtClientId": "Идентификатор клиента",
  "txtClientIdExisted": "Идентификатор клиента уже существует, используйте другой идентификатор клиента",
  "txtClientIdTooltip": "Уникальный идентификатор вашего сервиса. \nОн создается автоматически на основе\nпо названию вашей службы",
  "txtClose": "Закрывать",
  "txtCodeHasBeenCopied": "код был скопирован",
  "txtCodeSample": "Пример кода",
  "txtCompany": "Компания",
  "txtCompanyAddress": "Адрес компании",
  "txtCompanyAddressCity": "Город",
  "txtCompanyAddressCountry": "Страна",
  "txtCompanyAddressLine1": "Адресная строка 1",
  "txtCompanyAddressLine1IsRequired": "Строка адреса компании 1 является обязательной.",
  "txtCompanyAddressLine2": "Адресная строка 2 (необязательно)",
  "txtCompanyAddressPostalCode": "Почтовый индекс",
  "txtCompanyAddressPostalCodeRequired": "Требуется почтовый индекс.",
  "txtCompanyAddressState": "Состояние",
  "txtCompanyCity": "Город",
  "txtCompanyCountryIsRequired": "Укажите страну",
  "txtCompanyCustomRequest": "Сообщение",
  "txtCompanyEmailIsRequired": "Требуется адрес электронной почты компании.",
  "txtCompanyGroup": "3. Компания",
  "txtCompanyInfo": "Информация о компании",
  "txtCompanyInfomation": "Информация о компании",
  "txtCompanyName": "Название компании",
  "txtCompanyNameIsRequired": "Требуется название компании",
  "txtCompanyNameRepIsRequired": "Необходимо указать имя представителя компании.",
  "txtCompanyNumber": "Номер компании",
  "txtCompanyNumberIsRequired": "Требуется номер компании",
  "txtCompanyPhoneRepIsRequired": "Телефон представителя компании обязателен.",
  "txtCompanyPostalCode": "Почтовый индекс",
  "txtCompanyRepresentative": "Имя представителя компании",
  "txtCompanyState": "Состояние",
  "txtCompanyVAT": "Налоговый номер или номер НДС",
  "txtCompanyVATNumber": "Налоговый номер или номер НДС",
  "txtCompanyVATNumberIsRequired": "Требуется НДС-номер компании.",
  "txtConfiguration": "Конфигурация",
  "txtConfirm": "Подтверждать",
  "txtConfirmDeleteCompanyInfo": "Если вы вернетесь к предыдущему шагу, любая введенная информация о компании будет удалена.",
  "txtConnectWithUs": "Свяжитесь с нами",
  "txtContactSale": "Контактный отдел продаж",
  "txtContactSaleTeam": "Свяжитесь с нашим отделом продаж",
  "txtContactSupport": "Контактный отдел продаж",
  "txtContent": "Содержание",
  "txtContinue": "Продолжать",
  "txtContinueToConfigureService": "Продолжайте настраивать свой сервис",
  "txtCopied": "Скопировано!",
  "txtCopy": "Копировать",
  "txtCopyAndClose": "Скопировать и закрыть",
  "txtCopyApiKeyWarning": "Вы не сможете получить его, как только закроете всплывающее окно. Храните его в безопасном месте. \nВ случае потери вам придется сгенерировать новый ключ.",
  "txtCopyLink": "Скопировать ссылку",
  "txtCopyright": "© Блокпасс ",
  "txtCount": "Считать",
  "txtCountryRestriction": "Ограничения по странам",
  "txtCountryRestrictionDesc1": "Вы можете исключить жителей одних стран или принять только жителей других. Эта опция требует, чтобы пользователи указали свой адрес проживания.",
  "txtCountryRestrictionDesc2": "Страна, представленная здесь, является лишь примером. Вам следует проконсультироваться со своим специалистом по соблюдению требований относительно стран, в которых необходимо ввести ограничения.",
  "txtCountryRestrictionDescAllow": "Разрешить только жителям",
  "txtCountryRestrictionDescExclude": "Исключить жителей из",
  "txtCoutryOfIncorporation": "Страна регистрации",
  "txtCreate": "Создавать",
  "txtCreateAPIKey": "Создать ключ API",
  "txtCreateAPIKeyFooter1": "Публичное раскрытие ключа API может привести к компрометации личных данных ваших пользователей. ",
  "txtCreateAPIKeyFooter2": "Вы должны следовать ",
  "txtCreateAPIKeyFooter3": " для безопасного использования ключей API.",
  "txtCreateAccount": "Создал новую учетную запись",
  "txtCreateApiKeySuccess": "Создан новый ключ API.",
  "txtCreateBillingPlan": "Создать тарифный план",
  "txtCreateCertificate": "Создал новый сертификат",
  "txtCreateCheckConfig": "Создать конфигурацию проверки",
  "txtCreateCompany": "Создать компанию",
  "txtCreateFlowConfig": "Создать конфигурацию потока",
  "txtCreateIdentityField": "Создано поле идентификации",
  "txtCreateKYCSettings": "Созданы настройки KYC",
  "txtCreateNewTag": "Создать тег ",
  "txtCreateRuntimeCfg": "Создана конфигурация времени выполнения.",
  "txtCreateService": "Создан новый сервис",
  "txtCreateServiceDashboard": "Создать сервис",
  "txtCreateServiceSuccess": "Новая услуга успешно создана",
  "txtCreateVersion": "Создана новая версия API",
  "txtCreateWebhook": "Вебхук создан.",
  "txtCreatedAt": "Создано в",
  "txtCryptoAddresses": "Крипто-адреса",
  "txtCurrentPlan": "Текущий план",
  "txtCustomRegistrationUrl": "Пользовательский URL-адрес регистрации",
  "txtCustomUrlInvalid": "Этот URL-адрес недействителен.",
  "txtCustomiseAppLink": "Ссылка на приложение для мобильных устройств",
  "txtDAY": "День",
  "txtDOB": "Дата рождения",
  "txtDashboard": "Панель управления",
  "txtDataEncryption": "Шифрование данных",
  "txtDataEncryptionDes": "Загрузите открытый ключ PEM для использования со службой зашифрованного хранилища.",
  "txtDataExport": "Экспорт данных",
  "txtDate": "Дата",
  "txtDelete": "Удалить",
  "txtDeleteAPIKey": "Удалить ключ API",
  "txtDeleteApiKeySuccess": "Ключ API удален.",
  "txtDeleteBulkIdentityRecordConfirmMessage": "Вы уверены, что хотите удалить эти аккаунты?",
  "txtDeleteThisApiKey": "Удалить этот ключ API",
  "txtDeleteVersion": "Удалена версия API",
  "txtDeleteWebhook": "Вебхук удален.",
  "txtDescription": "Описание",
  "txtDescriptionIsRequired": "Введите описание этого ключа API.",
  "txtDetachPaymentMethod": "Отключить способ оплаты",
  "txtDetails": "Подробности",
  "txtDisabled": "Неполноценный",
  "txtDiscard": "Отказаться",
  "txtDiscardRichContent": "Отменить богатый контент",
  "txtDisplay": "Отображать",
  "txtDoNotSendAddMember": "У вас нет разрешения приглашать нового участника. Пожалуйста, свяжитесь с владельцем сервиса. Код ошибки 403.",
  "txtDocs": "Документы",
  "txtDocumentation": "Документация",
  "txtDonNotHaveService": "У вас нет услуг в этой компании",
  "txtDontHavePaymentMethod": "У вас нет способа оплаты. Добавьте способ оплаты ниже.",
  "txtDowngradeBpOperator": "Понизить версию",
  "txtDownload": "Скачать",
  "txtDownloadLogs": "Скачать журналы",
  "txtDrivingLicense": "Водительские права",
  "txtDropFileHere": "Скиньте сюда свой логотип",
  "txtEditClientId": "Изменить идентификатор клиента",
  "txtEditClientIdWarning": "Этот идентификатор клиента не может быть изменен позже и должен быть уникальным.",
  "txtEditConfig": "Изменить конфигурацию",
  "txtEditMembers": "Редактировать участников",
  "txtEditNewsContent": "Редактировать новости",
  "txtEditSearch": "Редактировать поиск",
  "txtEmail": "Электронная почта",
  "txtEmailAddress": "Адрес электронной почты",
  "txtEmailRepresentative": "Электронная почта представителя компании",
  "txtEmailWrongFormat": "Неправильный формат электронной почты",
  "txtEnable": "Давать возможность",
  "txtEnableApiKeys": "Включить ключи API",
  "txtEnableApiKeysDes": "Разрешить клиентам использовать ключи API для доступа к данным пользователей.",
  "txtEnableEndUserRegistrations": "Включить регистрацию конечных пользователей",
  "txtEnableEndUserRegistrationsDes": "Позволяет конечным пользователям регистрироваться для проверки с помощью этой службы.",
  "txtEnableKycDashboard": "Включить панель управления KYC",
  "txtEnableKycDashboardDes": "Разрешите клиентам доступ к панели KYC.",
  "txtEnableService": "Включить службу",
  "txtEnableServiceDes": "Этот вариант опасен; частое включение и отключение службы может привести к серьезным ошибкам.",
  "txtEnabled": "Включено",
  "txtEnabled2FA": "Включен 2FA",
  "txtEndDate": "Дата окончания",
  "txtEnglish": "Английский",
  "txtEnterpriseDesc1": "Минимум 100 профилей в месяц",
  "txtEnterpriseDesc2": " для получения дополнительной информации.",
  "txtEnterpriseSentDesc1": "Благодарим вас за выбор плана Enterprise. Наш отдел продаж свяжется с вами как можно скорее и настроит услугу в соответствии с вашими требованиями. \n",
  "txtEnterpriseSentDesc2": "А пока добавьте способ оплаты и заполните платежную информацию. Это необходимо для активации вашего плана.",
  "txtErrorUnsupportFile": "Неподдерживаемый файл",
  "txtEthAddress": "Адрес Эфириума",
  "txtEthereumAddress": "Адрес Эфириума",
  "txtEvent": "Событие",
  "txtExample": "Пример",
  "txtExcept": "кроме",
  "txtExcluded": "Исключено",
  "txtExcludedCountries": "исключенные страны",
  "txtExpiryDate": "Дата истечения срока действия",
  "txtExploreKyc": "Изучите панель управления KYC Connect. ",
  "txtExportServiceConfig": "Экспорт конфигурации сервиса",
  "txtFaceMatch": "Face Match",
  "txtFaceMatchCheck": "Проверка совпадения лиц",
  "txtFaceMatchDesc1": "Blockpass сравнивает изображение на идентификаторе пользователя с живым селфи, запрошенным при регистрации.",
  "txtFaceMatchDesc2": "Для этой проверки пользователи должны предоставить документ, удостоверяющий личность, и живое селфи.",
  "txtFacematchCheck": "Проверка соответствия лиц",
  "txtFailedToSendMemberInvitation": "Не удалось отправить приглашение участника.",
  "txtFamilyName": "Фамилия",
  "txtFaq": "Часто задаваемые вопросы",
  "txtFeatureInProgress": "Функция в разработке",
  "txtFileNotSupport": "Этот файл не поддерживается",
  "txtFillCompanyInfo": "Заполните информацию на основе существующей компании ниже или добавьте новую компанию.",
  "txtFlowAddress": "Адрес потока (FLOW)",
  "txtFlowDebugger": "Отладчик потока",
  "txtFlowDebuggerDesc": "Поиск потоков по Flow ID. Потоки могут включать в себя несколько проверок",
  "txtFlowsConfig": "Конфигурация потоков",
  "txtFlowsConfigDesc": "Настройка проверок, определяющих поток",
  "txtForbidden": "У вас нет разрешения на выполнение этого действия.",
  "txtForceAddOperator": "Добавить оператора в сервис",
  "txtForceAddOperatorDesc": "Владельцы сервисов не разрешили Blockpass. Еще раз проверьте, есть ли у вас полномочия назначать операторов.",
  "txtFreePlanDescription": "Вы получаете непроверенные личности. Ограничено 10 в день.",
  "txtFreeTrial": "Бесплатная пробная версия",
  "txtFrench": "Французский",
  "txtFunctionUnavailable": "Функция временно недоступна",
  "txtGeneralInfomation": "Общая информация",
  "txtGeoRestriction": "Географические ограничения",
  "txtGetServiceDisplayFailed": "Ошибка отображения служб.",
  "txtGetServiceListFailed": "Получить список услуг не удалось.",
  "txtGetServiceWebhookListFailed": "Получить веб-перехватчики служб не удалось.",
  "txtGivenName": "Собственное имя",
  "txtGoToDashboard": "ПЕРЕЙТИ НА ПАНЕЛИ",
  "txtGoToServiceDashboard": "Перейти в личный кабинет сервиса",
  "txtGoToServiceList": "ПОЗЖЕ",
  "txtGotIt": "Понятно",
  "txtGotoDashboardDesc1": "Перейдите на панель управления KYC Connect для этой услуги.",
  "txtGotoDashboardDesc2": "Панель мониторинга — это место, где ваша команда может просматривать удостоверения, загруженные в ваш сервис отдельными лицами.",
  "txtHelp": "Помощь",
  "txtIdEngine": "Идентификатор двигателя",
  "txtIdEngineDesc": "Инструмент оптического распознавания символов Test ID Engine",
  "txtIdentityDesc1": "Blockpass проверяет паспорта, национальные удостоверения личности и водительские права. ",
  "txtIdentityDesc2": "Пользователям предлагается сделать живое изображение своего документа.",
  "txtIdentityDesc3": "Этот вариант требует от пользователей предоставить документ, удостоверяющий личность, полное имя и дату рождения.",
  "txtIdentityDocument": "Документ, удостоверяющий личность",
  "txtIdentityDocumentCheck": "Проверка документов, удостоверяющих личность",
  "txtIdentityLink1": "Посмотреть список поддерживаемых стран",
  "txtImageTooBig": "Изображение слишком большое",
  "txtImgDimensionBetween10242048": "Пожалуйста, загрузите изображение размером от 512x512 до 2048x2048 пикселей.",
  "txtImgDimensionRule": "PNG, JPEG. Мин. 512x512 пикселей, максимум 2048x2048 пикселей.",
  "txtImpactChanging": "Ударная зарядка",
  "txtInactive": "Неактивный",
  "txtIncludeRawDataExceptPictureTooltip": "Возвращать метаданные и атрибуты идентификации, кроме изображений.",
  "txtIncludeRawDataIncludePictureTooltip": "Возвращать метаданные и атрибуты идентификации, включая изображения.",
  "txtIncludeRawDataTooltip": "Возвращать метаданные и атрибуты идентификации, кроме изображений.",
  "txtIncluding": "включая",
  "txtIncorrectFormat": "Поле должно содержать только обычные/ВЕРХНИЕ символы, цифры и подчеркивание (_).",
  "txtInfomation": "Информация",
  "txtInputYourServiceName": "Введите название вашей услуги",
  "txtInternalServerError": "На сервере произошла непредвиденная ошибка",
  "txtInvalidEmailFormat": "Неверный формат электронной почты",
  "txtInvalidEmails": "Неверные адреса электронной почты",
  "txtInvalidParams": "Запрос содержит недопустимые параметры. Пожалуйста, проверьте введенные данные еще раз.",
  "txtInvalidURL": "URL-адрес недействителен",
  "txtInvalidUrl": "Этот URL-адрес недействителен",
  "txtInvitationRemoved": "Приглашение удалено.",
  "txtInvitationResent": "Приглашение отправлено повторно",
  "txtInvitationSent": "Приглашение отправлено на",
  "txtInviteAlreadySent": "Приглашение уже отправлено",
  "txtInviteUser": "Приглашенный пользователь",
  "txtIsCopiedToClipboard": "копируется в буфер обмена.",
  "txtIsSuccess": "Успех",
  "txtJapanese": "японский",
  "txtJobId": "Идентификатор вакансии",
  "txtKYCBot": "KYC-бот",
  "txtKYCDashboardAccess": "Доступ к панели KYC",
  "txtKybDashboard": "Панель управления КИБ",
  "txtKybOnboardingContent": "Теперь вы можете просматривать и управлять своими услугами KYB здесь.",
  "txtKybOnboardingTitle": "Новый! Услуги KYB здесь 👋",
  "txtKybService": "КЮБ Сервис",
  "txtKybServiceDesc": "KYB означает «Знай свой бизнес». Создайте службу KYB для проверки личности предприятий.",
  "txtKybServiceName": "KYB Название службы",
  "txtKybServiceNonBlockpassWarning": "Эта функция доступна только для корпоративных клиентов. Свяжитесь с менеджером вашего аккаунта Blockpass или <0>.",
  "txtKybServices": "КЮБ Услуги",
  "txtKycDashboard": "Панель управления KYC",
  "txtKycService": "Служба KYC",
  "txtKycServiceDesc": "KYC означает «Знай своего клиента». Создайте сервис KYC для проверки личности физических лиц.",
  "txtKycServiceName": "Название службы KYC",
  "txtKycServices": "Услуги KYC",
  "txtLast180Days": "Последние 180 дней",
  "txtLast30Days": "Последние 30 дней",
  "txtLast7Days": "Последние 7 дней",
  "txtLast90Days": "Последние 90 дней",
  "txtLastMonth": "В прошлом месяце",
  "txtLastRunAt": "Последний запуск в",
  "txtLatestInvoice": "Последний счет",
  "txtLatestInvoiceDesc": "Счета отправляются на адрес электронной почты владельца выставления счетов каждый месяц. Вы также можете скачать последний выставленный счет ниже.",
  "txtLearnMoreAboutApiKey": "Узнайте больше об API Blockpass. ",
  "txtLengthTooShort": "Длина поля слишком коротка",
  "txtLink": "Связь",
  "txtListDeleteEmail": "Список писем, которые вы хотите удалить, через запятую, до 50 писем.",
  "txtListOfServices": "Список услуг",
  "txtListingRunningCheck": "Листинг текущей проверки",
  "txtListingRunningCheckDesc": "Листинг Описание выполнения проверки",
  "txtLive": "Жить",
  "txtLockUser": "Заблокированный пользователь",
  "txtLogin": "Авторизоваться",
  "txtLogo": "Логотип",
  "txtLogoDesc": "PNG, JPEG, JPG. \nМинимум 512x512 пикселей, максимум 2048x2048 пикселей.",
  "txtLogoRestriction": "Размер изображения должен быть от 1024 x 1024 до 2048 x 2048 пикселей.",
  "txtLogoSizeError": "Логотип, который вы только что загрузили, имеет неправильный размер.",
  "txtLogout": "Выход из системы",
  "txtLogs": "Журналы",
  "txtLongDescription": "Подробное описание",
  "txtLongDescriptionTooltip": "Полное описание вашей услуги (только неформатированный текст)",
  "txtMONTH": "Месяц",
  "txtMarkerPlace": "Торговая площадка",
  "txtMarkerPlaceDesc": "Управление записями на торговой площадке",
  "txtMarketPlace": "Торговая площадка – незавершенное производство",
  "txtMarketplace": "Торговая площадка",
  "txtMarketplaceSettingUpdateSuccess": "Настройки торговой площадки успешно обновлены.",
  "txtMaticAddress": "Адрес полигона (Matic)",
  "txtMaxEmailsExceeded": "Максимум 50 писем за одно удаление.",
  "txtMemberInfomation": "Информация об участниках",
  "txtMemberRemoved": "был удален",
  "txtMetaDataOnlyTooltip": "Возвращать только метаданные (статус профиля, идентификаторы,...)",
  "txtMore": "Более",
  "txtMoreDetails": "Подробнее",
  "txtMoreInfomation": "Дополнительная информация",
  "txtName": "Имя",
  "txtNameOfCompany": "Название компании",
  "txtNameOfCompanyIsRequired": "Требуется название компании",
  "txtNameOnCard": "Имя на карточке",
  "txtNameOnCardIncomplete": "Имя на карточке неполное.",
  "txtNameRepresentative": "Имя представителя компании",
  "txtNationalID": "Национальное удостоверение личности",
  "txtNationalId": "Национальный идентификатор",
  "txtNewCompany": "Новая компания",
  "txtNewsContent": "Новостной контент",
  "txtNext": "Следующий",
  "txtNoActivityLogs": "Нет мероприятий, соответствующих вашему запросу",
  "txtNoActivityLogsDesc": "Попробуйте быть менее конкретными или использовать другие ключевые слова.",
  "txtNoApiKeyDesc": "Ключ API необходим для извлечения личных данных отдельных лиц, включая метаданные, личную информацию и изображения. ",
  "txtNoApiKeys": "Нет ключей API",
  "txtNoCompanyAdd": "Вы еще не добавили никакой информации о компании",
  "txtNoCompanyAddDesc": "Нажмите ниже, чтобы добавить первую информацию о компании.",
  "txtNoCountryRestriction": "Нет страны. Воспользуйтесь поиском, чтобы добавить страну в список",
  "txtNoData": "Записи не найдены, попробуйте расширить запрос!",
  "txtNoDataMemberInService": "Операторы не добавлены, пожалуйста, сначала добавьте оператора в эту услугу! ",
  "txtNoFieldsAreUpdated": "Ни одно поле не обновляется",
  "txtNoInvoiceDesc1": "Счета отправляются на адрес электронной почты владельца выставления счетов каждый месяц. ",
  "txtNoInvoiceDesc2": "Нет выставленного счета, который можно скачать сейчас.",
  "txtNoServiceMatch": "Нет услуг, соответствующих вашему запросу",
  "txtNoServiceMatchDesc": "Попробуйте быть менее конкретными или использовать другие ключевые слова.",
  "txtNoSetupCost": "Нет затрат на установку",
  "txtNoUsageDetail": "Нет данных для отображения",
  "txtNoUsageDetailDesc": "На данный момент статистики нет.",
  "txtNone": "Никто",
  "txtNoneApprovalDesc": "Руководство: ",
  "txtNoneApprovalDesc1": "Одобрить или отклонить каждого пользователя вручную.",
  "txtNormalDesc1": "Максимум 100 профилей в месяц.",
  "txtNotStoreCard": "Blockpass не хранит данные вашей карты.",
  "txtNotifications": "Уведомления",
  "txtNotificationsDesc": "Отправляйте push-уведомления мобильным пользователям",
  "txtOnboarding": "Регистрация",
  "txtOnboardingGroup": "2. Регистрация",
  "txtOnboardingSetting": "Настройки регистрации",
  "txtOnly": " только ",
  "txtOpenDashboard": "Открыть панель управления",
  "txtOperatorAssignment": "Назначение оператора",
  "txtOperatorAssignmentKYC": "Назначение оператора",
  "txtOperatorList": "Список операторов",
  "txtOverview": "Обзор",
  "txtPOA": "Подтверждение адреса",
  "txtPage403Desc1": "У вас нет разрешения на\n получить доступ к этой услуге",
  "txtPage403Desc2": "Вернитесь в раздел «Услуги» и найдите нужную услугу или свяжитесь с владельцем услуги.",
  "txtParametersValidationError": "Запрос содержит недопустимые параметры. Пожалуйста, проверьте введенные данные еще раз.",
  "txtPassport": "Паспорт",
  "txtPassword": "Пароль",
  "txtPayAsYouGo": "Платите по мере использования",
  "txtPaymentMethod": "Способ оплаты",
  "txtPaymentMethodDeleted": "Способ оплаты не может быть получен или был удален. ",
  "txtPermissionDenied": "У вас нет разрешения на выполнение этого действия.",
  "txtPersonalAtt": "Личные качества",
  "txtPersonalAttributes": "Личные качества",
  "txtPhoneNumber": "Номер телефона",
  "txtPhoneNumberWrongFormat": "Номер телефона должен состоять из цифр",
  "txtPhoneRepresentative": "Телефон представителя компании",
  "txtPickYourServiceName": "Выберите название для своего сервиса",
  "txtPlan": "План",
  "txtPlanConfig": "Конфигурация планов",
  "txtPlanConfigDesc": "процесс отправки людям счетов с просьбой оплатить задолженность",
  "txtPlanDescription1": "Менее @@num_ident@@ идентификаторов в день и на компанию.\n",
  "txtPlanDescription2": "Время обработки более 24 часов. Стандартный ",
  "txtPleaseCopyAPIKey": "Пожалуйста, скопируйте ключ API",
  "txtPleaseReplacePaymentMethod": "Пожалуйста, замените способ оплаты.",
  "txtPleaseSelectOperator": "Пожалуйста, выберите оператора.",
  "txtPleaseSelectUser": "Пожалуйста, выберите пользователя.",
  "txtPostalCodeWrongFormat": "Формат неправильный.",
  "txtPrivacy": "Конфиденциальность",
  "txtPrivacyPolicy": "Политика конфиденциальности",
  "txtProfile": "Профиль",
  "txtPromote": "Продвигать",
  "txtPromoteBpOperator": "Продвигать",
  "txtPromoteOperator": "Добавить оператора",
  "txtPromoteOperatorSuccess": "Успешно продвигайте оператора.",
  "txtProofOfAddress": "Подтверждение адреса",
  "txtProvideNameOfService": "Укажите название вашего сервиса",
  "txtPublicLinkDesc": "Любой человек в Интернете, у которого есть ссылка, может отправить свой профиль <0>.",
  "txtPublishService": "Опубликованный сервис",
  "txtPushNotification": "Push-уведомление",
  "txtRedirect": "Перенаправление",
  "txtRemove": "Удалять",
  "txtRemoveAccess": "Удалить доступ",
  "txtRemoveBlockpassAccess": "Удалить доступ к Blockpass",
  "txtRemoveBlockpassAccessDesc": "Вы уверены, что хотите удалить доступ к Blockpass? Это не позволит команде поддержки Blockpass получить доступ к вашей панели KYC.",
  "txtRemoveBpOperator": "Удалять",
  "txtRemoveInvitation": "Удалить приглашение",
  "txtRemoveMember": "Удалить этого участника?",
  "txtRemoveMemberDesc": "Пригласите этого участника повторно позже, нажав кнопку «ДОБАВИТЬ УЧАСТНИКА» выше.",
  "txtRemoveOperator": "Удалить оператора",
  "txtRemoveOperatorDesc": "Вы уверены, что хотите удалить этого оператора?",
  "txtRemoveOperatorSuccess": "Удаление оператора успешно.",
  "txtRemoveTagConfirmContent": "Вы уверены, что хотите удалить этот тег?",
  "txtRemoveTagConfirmTitle": "Удалить тег «<0>»",
  "txtRemoveTagSuccess": "Тег «<0>» удален.",
  "txtRemoveThisMember": "Удалить этого участника",
  "txtRemoveUser": "Удаленный пользователь",
  "txtReplace": "Заменять",
  "txtReplacePaymentMethod": "Заменить способ оплаты",
  "txtReplacePaymentMethodDesc1": "Новая карта будет использоваться для оплаты всех ваших услуг. ",
  "txtReplacePaymentMethodDesc2": "Обратите внимание, что изменения способа оплаты затронут многие сервисы, использующие этот способ. \nспособ оплаты.",
  "txtReport": "Отчет",
  "txtRepresentative": "Представитель",
  "txtRequestAccess": "Запросить доступ",
  "txtRequestEnterprise": "Запрос на корпоративный план",
  "txtRequestEnterpriseSent": "Запрос на план Enterprise отправлен!",
  "txtRequired": "* необходимый",
  "txtRequiredOneIdentityDocument": "Пожалуйста, выберите хотя бы один документ, удостоверяющий личность",
  "txtRequirementPlaceHolder": "Расскажите нам больше о ваших требованиях и сроках",
  "txtResAddress": "Адрес проживания",
  "txtResend": "Отправить повторно",
  "txtResidentialAddress": "Адрес проживания",
  "txtRestricted": "Ограниченный",
  "txtRestrictedDesc1": "Зарегистрироваться могут только люди, прошедшие аутентификацию на вашем сайте.",
  "txtRestrictedDesc2": "Выберите этот вариант, если вы хотите отправить идентификатор пользователя в виджет.",
  "txtRestrictedDesc3": "Пожалуйста, введите URL-адрес, по которому установлен виджет на вашем сайте.",
  "txtRestrictedDescLink": "Дополнительная информация в документации.",
  "txtRestrictedLinkDesc1": "Заставьте пользователей войти на ваш сайт, прежде чем они смогут отправить свой профиль KYC.",
  "txtRestrictedLinkDesc2": "Выберите эту опцию, если вы хотите отправить идентификатор пользователя в виджет. ",
  "txtRestrictedLinkDesc3": "Дополнительная информация в документации.",
  "txtReturnAttributeData1": "Возвращать метаданные и атрибуты удостоверения",
  "txtReturnAttributeData2": "картинки",
  "txtReturnAttributeDataExceptPicture1": "Возвращать метаданные и атрибуты удостоверения",
  "txtReturnAttributeDataExceptPicture2": "картинки",
  "txtReturnMetaData1": "Возвращаться",
  "txtReturnMetaData2": "метаданные (статус профиля, идентификаторы,...)",
  "txtReturnToServices": "Вернуться к услугам",
  "txtRevoke": "Отозвать",
  "txtRevokeOperator": "Оператор отзыва",
  "txtRevokeOperatorDesc": "Вы уверены, что хотите отозвать этого оператора?",
  "txtRevokeOperatorSuccess": "Отозвать оператор успешно.",
  "txtRole": "Роль",
  "txtRoleUpdated": "Роль обновлена",
  "txtRuleArchiveTime": "Число от 1 до 9999",
  "txtRunningFlowConfig": "Запуск конфигурации потока",
  "txtRunningFlowConfigDesc": "Описание конфигурации Running Flow",
  "txtRuntimeConfig": "Конфигурация времени выполнения",
  "txtRuntimeConfigDesc": "Настройка параметров консоли и информационной панели",
  "txtRussian": "Русский",
  "txtSAVE": "СОХРАНЯТЬ",
  "txtSampleCode": "Пример кода",
  "txtSave": "Сохранять",
  "txtSaveChange": "Сохранить изменения",
  "txtSaveChanges": "Сохранить изменения",
  "txtScheduleDeleteProfilesSuccess": "Запланировать удаление профилей успешно",
  "txtScheduleForBulkDeletion": "Расписание массового удаления",
  "txtScope": "Объем",
  "txtSearchActivityLogs": "Поиск по имени пользователя/электронной почте",
  "txtSearchByEmail": "Поиск по электронной почте",
  "txtSearchByNameOrClientId": "Поиск по имени или идентификатору клиента",
  "txtSearchCountry": "искать страну",
  "txtSearchPlaceHolder": "Поиск...",
  "txtSeconds": "секунда(ы)",
  "txtSeeList": "См. список.",
  "txtSelectACryptoAddress": "Выберите криптоадрес",
  "txtSelectAPlan": "Выберите план",
  "txtSelectAPlanToUnlock": "ОБНОВИТЬ СВОЙ ПЛАН",
  "txtSelectCryptoAddress": "Пожалуйста, выберите криптоадрес.",
  "txtSelectIdentityAttributes": "Выбранные атрибуты удостоверения",
  "txtSelectPlan": "Выберите план",
  "txtSelfie": "Селфи",
  "txtSendInvite": "Отправить приглашение",
  "txtSendInviteEmail": "Приглашение отправлено! Он действителен в течение 3 дней.",
  "txtServiceAccountant": "Бухгалтер",
  "txtServiceAccountantTooltip": "Можно только просматривать вкладку «Использование» и настройки плана в консоли администратора.",
  "txtServiceAllowBlockpassAccess": "Разрешить доступ к блокпассу",
  "txtServiceBillingOwner": "Владелец счетов",
  "txtServiceBillingOwnerTooltip": "Все разрешения в консоли и информационной панели. Можно обновить информацию о компании и способ оплаты.",
  "txtServiceClientIdIsExisted": "Идентификатор клиента уже существует, используйте другой идентификатор клиента",
  "txtServiceCompanyInfomation": "информация о компании",
  "txtServiceDetail": "Детали услуги",
  "txtServiceEmailIsRequired": "Требуется адрес электронной почты службы поддержки.",
  "txtServiceListInvalidPage": "Неверное значение подкачки",
  "txtServiceLogo": "Логотип сервиса",
  "txtServiceLogoDesc": "Вы можете загрузить логотип для своего сервиса сейчас или обновить его позже в настройках.",
  "txtServiceName": "Название службы",
  "txtServiceNameIsRequired": "Укажите название службы.",
  "txtServiceNameOrClientId": "Название службы или идентификатор клиента",
  "txtServiceNameOrClientIdPlaceholder": "Введите название услуги или идентификатор клиента",
  "txtServiceNameTooltip": "Выберите название для вашего сервиса (максимум 64 символа)",
  "txtServiceNotFound": "Сервис не найден.",
  "txtServiceOwner": "Менеджер",
  "txtServiceOwnerTooltip": "Все разрешения в консоли и информационной панели.",
  "txtServicePortalIsRequired": "Требуется портал поддержки",
  "txtServiceReadOnlyRole": "Только чтение",
  "txtServiceReadOnlyRoleTooltip": "Можно только просматривать профили. Невозможно обработать KYC или обновить настройки.",
  "txtServiceReviewerKyb": "КИБ Рецензент",
  "txtServiceReviewerTooltipKyb": "Может обрабатывать только KYB. Невозможно удалить профили или обновить настройки.",
  "txtServiceReviewerTooltipkyc": "Может обрабатывать только KYC. Невозможно утвердить, удалить профили или обновить настройки.",
  "txtServiceReviewerkyc": "Рецензент KYC",
  "txtServiceTags": "Сервисные теги",
  "txtServiceTagsDes": "Добавьте или удалите любые теги для этой услуги.",
  "txtServices": "Услуги",
  "txtServicesUsage": "Недавнее использование услуг",
  "txtServicesUsageDesc": "процесс отправки людям счетов с просьбой оплатить задолженность",
  "txtSettings": "Настройки",
  "txtShareableDesc": "Эта страница позволяет заявителям отправлять информацию KYC на вашу панель управления. Скопируйте и вставьте ссылку в электронное письмо или в любое место, где вы хотите ею поделиться.",
  "txtShareableLink": "Ссылка для общего доступа",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "Отсутствует ссылка для общего доступа",
  "txtShortDescription": "Краткое описание",
  "txtShortDescriptionTooltip": "Краткий текст с описанием вашей услуги (только неформатированный текст).",
  "txtShowLess": "Показать меньше",
  "txtShowMore": "Показать больше",
  "txtShowRefId": "Показать рефид",
  "txtShowSession": "Показать сессию",
  "txtShowThisService": "Показать эту услугу",
  "txtShowThisServiceDes": "Включите или отключите эту услугу, чтобы отобразить ее на торговой площадке.",
  "txtSolanaAddress": "Солана (SOL) адрес",
  "txtStartDate": "Дата начала",
  "txtStartingAt": "Начиная с",
  "txtStartingAtTooltip": "Дополнительная информация недоступна.",
  "txtStatus": "Статус",
  "txtStringMaxLengthError": "Должно быть <0> или меньше символов.",
  "txtStripeError": "Что-то пошло не так при проверке вашей платежной информации. Пожалуйста, свяжитесь с нами для поддержки.",
  "txtSubscribe": "Подписаться",
  "txtSubscribeTo": "Подпишитесь на нашу рассылку",
  "txtSubscribed": "Подписан",
  "txtSubscription": "Подписка",
  "txtSupport": "Поддерживать",
  "txtSupportPortal": "URL-адрес вашего веб-портала поддержки",
  "txtSupportPortalTooltip": "URL-адрес вашего веб-сайта портала обслуживания клиентов",
  "txtSupportrEmail": "Ваш адрес электронной почты службы поддержки",
  "txtSupportrEmailTooltip": "Адрес электронной почты службы поддержки клиентов. Этот адрес используется, если вы не предоставляете URL-адрес портала поддержки. Адрес по умолчанию: support@blockpass.org.",
  "txtTags": "Тег(и)",
  "txtTaxNumberWrongFormat": "Неверный формат.",
  "txtTeam": "Команда",
  "txtTestService": "Тестовый сервис",
  "txtTheRequestWasMadeButNoResponseWasReceived": "Ошибка сети",
  "txtThisFieldIsRequired": "Это поле обязательно к заполнению.",
  "txtTitleAllow": "Разрешенные страны",
  "txtTitleExclude": "Исключенные страны",
  "txtToGetStart": "Чтобы начать, пожалуйста, войдите в систему.",
  "txtToday": "Сегодня",
  "txtTools": "Инструменты",
  "txtTooltipAddressCountryRestriction": "Адрес требуется, если включены ограничения по стране.",
  "txtTooltipAddressProofOfAddress": "Адрес требуется, если выбрано подтверждение адреса.",
  "txtTotal": "Общий",
  "txtTotalForMonth": "Итого за месяц ",
  "txtTwoFaBannerDesc": "Чтобы защитить ваши данные, нам теперь требуется двухфакторная аутентификация (2FA). Вы можете включить его сейчас или вам будет предложено включить его при следующем входе в систему.",
  "txtTwoFaBannerTitle": "Защитите свой аккаунт с помощью 2FA",
  "txtTwoFaButtonEnableNow": "Включите 2FA сейчас",
  "txtTypeSomthing": "Напечатайте что-нибудь...",
  "txtUSDperCheck": "Доллар США/чек",
  "txtUnAuthorized": "Сессия истекла или недействительна, пожалуйста, войдите снова",
  "txtUnauthorized": "Сессия истекла или недействительна, пожалуйста, войдите снова",
  "txtUnlimittedCollaborator": "Неограниченное количество соавторов",
  "txtUnsubscribe": "Отписаться",
  "txtUpdate": "Обновлять",
  "txtUpdateAPIKey": "Обновить ключ API",
  "txtUpdateApiKey": "Обновить ключ API",
  "txtUpdateApiKeySuccess": "Ключ API обновлен",
  "txtUpdateApproveMode": "Обновление режима одобрения",
  "txtUpdateBillingPlanDisplay": "Обновить отображение плана выставления счетов",
  "txtUpdateBillingPlanKycBot": "Обновить конфигурацию KYC-бота",
  "txtUpdateBillingPlanVerification": "Обновить конфигурацию проверки KYC",
  "txtUpdateBpOperatorRole": "Изменить роль",
  "txtUpdateCertificate": "Обновлен сертификат",
  "txtUpdateCheckConfig": "Обновление конфигурации проверки",
  "txtUpdateCompany": "Обновить компанию",
  "txtUpdateCompanyInfoDesc": "Обновите информацию о компании.",
  "txtUpdateEnterpriseDesc1": "Бесплатное хранение персональных данных",
  "txtUpdateFlowConfig": "Обновить конфигурацию потока",
  "txtUpdateFreePlanDescription": "Вы получаете непроверенные личности. Хранение данных: 1 неделя",
  "txtUpdateIdentityField": "Обновлено поле идентификации",
  "txtUpdateInfo": "Обновить информацию",
  "txtUpdateKYCSettings": "Обновлены настройки KYC.",
  "txtUpdateNormalDesc1": "Хранение персональных данных: до 4 недель",
  "txtUpdateOperator": "Обновить оператор",
  "txtUpdateOperatorSuccess": "Обновить оператор успешно.",
  "txtUpdatePlanDescription1": "Хранение персональных данных: до 4 недель",
  "txtUpdatePlanDescription2": "Время обработки может варьироваться. Стандартный ",
  "txtUpdatePlanSuccess": "Обновите план выставления счетов за услуги успешно.",
  "txtUpdateRuntimeCfg": "Обновленная конфигурация времени выполнения.",
  "txtUpdateService": "Обновленный сервис",
  "txtUpdateServiceSuccess": "Все изменения сохранены",
  "txtUpdateWebhook": "Обновить вебхук",
  "txtUpdateWebhookSuccess": "Вебхук обновлен",
  "txtUploadALogo": "Загрузить логотип ",
  "txtUploadPublicKey": "Загруженный открытый ключ",
  "txtUrlPrivacyPolicy": "URL-адрес вашей Политики конфиденциальности",
  "txtUrlPrivacyPolicyTooltip": "Ссылка на вашу Политику конфиденциальности будет отображаться перед регистрацией пользователя.",
  "txtUrlTermsAndConditions": "URL-адрес ваших Положений и условий",
  "txtUrlTermsAndConditionsTooltip": "Ссылка на ваши Условия использования будет отображаться перед регистрацией пользователя.",
  "txtUsage": "Использование",
  "txtUsageMetrics": "Метрики использования",
  "txtUserDebugger": "Пользовательский отладчик",
  "txtUserDebuggerDesc": "Поиск пользователей по электронной почте или идентификатору поддержки",
  "txtUserId": "ID пользователя",
  "txtUserSessionExpired": "Срок действия сеанса пользователя истек",
  "txtUserSessionExpiredDesc": "Вы вышли из системы. Пожалуйста, войдите еще раз",
  "txtUsername": "Имя пользователя",
  "txtUsernameOrEmail": "Имя пользователя или адрес электронной почты",
  "txtUsernameOrEmailPlaceholder": "Введите имя пользователя или адрес электронной почты",
  "txtVATNumber": "НДС номер",
  "txtVerification": "Проверки",
  "txtVerificationGroup": "1. Проверки",
  "txtVerifications": "Проверки",
  "txtViewDetails": "Посмотреть детали",
  "txtVisitThePage": "Посетите страницу",
  "txtWEEK": "Неделя",
  "txtWarningApprovalMode": "Пожалуйста, подтвердите способ одобрения, прежде чем перейти к следующему шагу.",
  "txtWavesAddress": "Адрес волны",
  "txtWebhooks": "Вебхуки",
  "txtWebsite": "URL вашего сайта",
  "txtWebsiteIntegration": "Интеграция виджетов",
  "txtWebsiteTooltip": "Ссылка на ваш официальный сайт",
  "txtWebsiteUrlIsRequired": "Пожалуйста, заполните это поле",
  "txtWelcomeBody": "Создавайте, настраивайте и отслеживайте службы для проверки личности ваших клиентов.",
  "txtWelcomeTitle": "Добро пожаловать в консоль администратора Blockpass",
  "txtWelcomeTo": "Добро пожаловать в",
  "txtWhoCanResgister": "Кто может зарегистрироваться?",
  "txtWillBeDeleted": "будет удалено. ",
  "txtWillRemoved": "будет удалено.",
  "txtYEAR": "Год",
  "txtYesterday": "Вчера",
  "txtYourApiKey": "Ваш API-ключ",
  "txtYourCard": "Ваша карта",
  "txtYourCompanyInfo": "Информация о вашей компании",
  "txtYourEmailAddress": "Ваш электронный адрес",
  "txtYourLink": "Ваша ссылка",
  "txtYourLinkCopiedToClipboard": "Ваша ссылка скопирована в буфер обмена",
  "txtYourPaymentMethod": "Ваш способ оплаты",
  "txtYourPaymentMethodGroup": "4. Способ оплаты",
  "txtYourServiceConfigCopiedToClipboard": "Конфигурация вашей службы скопирована в буфер обмена.",
  "txtYourServiceCreated": "Ваш сервис создан",
  "txtYourServiceLink": "Ссылка на ваш сервис",
  "txtYourWebsite": "https://ваш-сайт.com",
  "txtYourWebsiteUrl": "URL-адрес вашего веб-сайта",
  "txtYourWebsiteUrlDesc": "Пожалуйста, введите URL сайта, на котором вы установили виджет",
  "txtdelete": "удалить",
  "txtdonwloadMonitorReport": "Загрузить отчет монитора",
  "txtsettingUpdated": "Настройка обновлена"
} // eslint-disable-line no-template-curly-in-string