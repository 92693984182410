import useRootStore from '@/hooks/useRootStore'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const TutorialKybHeader = observer(() => {
  const rootStore = useRootStore()

  useEffect(() => {
    if (!rootStore.UITutorialStore.tutorials.kybOnBoarding) return
    rootStore.UITutorialStore.startTutorial('kybOnBoarding')

    return () => {
      rootStore.UITutorialStore.stopTutorial()
    }
  }, [rootStore.UITutorialStore])

  return null
})
