export interface ITutorialStep {
  step: number
  title: string
  content: string
}

export interface ITutorialItem {
  currentStep: number
  finished: boolean
  steps: ITutorialStep[]
}

export interface ITutorial {
  kybOnBoarding: ITutorialItem
}

export const TUTORIAL_VERSION = '1'

export const TUTORIAL_PLACEMENT_MAP = {
  kybOnBoarding: 'bottom',
} as const

export const TUTORIAL_TARGET_MAP = {
  kybOnBoarding: ['kybMenu'],
}

export const TUTORIALS = {
  kybOnBoarding: {
    currentStep: 0,
    finished: false,
    steps: [
      {
        step: 0,
        title: 'txtKybOnboardingTitle',
        content: 'txtKybOnboardingContent',
      },
    ],
  },
}

export const TUTORIAL_TRIGGER_DELAY = 1500
