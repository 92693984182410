// ------------------- REGISTER PROGRESS --------------

export const REGISTER_ACCEPT_TERM = 0
export const REGISTER_VALIDATE_EMAIL = 1
export const REGISTER_VERIFY_EMAIL = 2
export const REGISTER_INFO = 3
export const REGISTER_INVITE = 4

export const DEFAULT_AVATAR =
  'https://cdn.blockpass.org/developer-portal/default_avatar_large.png'

export const URL_REGISTER_SERVICE = 'https://blockpass.org/marketplace/'
export const URL_REGISTER_PASS_CLUB_REGISTER_AGREEMENT =
  'https://passverify.blockpass.org/#/terms'
export const URL_PRIVACY_POLICY =
  'https://blockpass.org/foundation-privacy-policy/'
export const URL_PASSCLUB_BENEFITS = 'https://blockpass.org/passclub/'
export const URL_LEARN_MORE_TWOFA =
  'https://help.blockpass.org/hc/en-gb/search?utf8=%E2%9C%93&query=Setting+up+Two-Factor+Authentication'

export const BIG_DESKTOP_MAX_WIDTH_CONTENT = 1140
export const DESKTOP_MAX_WIDTH_CONTENT = 1140
export const TABLET_MAX_WIDTH_CONTENT = 667

export const REDIRECT_TO_AUTH_HOST =
  'https://qa-oauth.blockpass.org?acctype=bp_wid&redirect_uri=%my_account_host%&client_id=blockpass-intl'

//
export const WIDTH_SERVICE_THUMB = 200
export const MAX_WIDTH_SERVICE_LOGO = 2048
export const MIN_WIDTH_SERVICE_LOGO = 512
export const VALID_LOGO_TYPE = ['image/png', 'image/jpeg']
// link
export const REDIRECT_TO_MERCHANT_TERMS =
  'https://www.blockpass.org/merchant-terms/'
export const REDIRECT_TO_ANNOUNCEMENTS =
  'https://t.me/joinchat/AAAAAFPHloysisffl60AvQ'
export const REDIRECT_TO_FAQ = 'https://help.blockpass.org/hc/en-gb'
export const REDIRECT_TO_PRIVACY = 'https://www.blockpass.org/privacy/'
export const REDIRECT_TO_DOCUMENT = 'https://docs.blockpass.org/'
export const REDIRECT_TO_SUPPORT =
  'https://help.blockpass.org/hc/en-gb/requests/new'
export const REDIRECT_TO_FACEBOOK = 'https://www.facebook.com/blockpassorg/'
export const REDIRECT_TO_LINKEDIN =
  'https://www.linkedin.com/company/blockpass/'
export const REDIRECT_TO_YOUTUBE =
  'https://www.youtube.com/channel/UC4HuNuNfNTRtF4cUtqGyL2A'
export const REDIRECT_TO_GITHUB = 'https://github.com/blockpass-org'
export const REDIRECT_TO_TELEGRAM = 'https://t.me/blockpass'
export const REDIRECT_TO_TWITTER = 'https://twitter.com/BlockpassOrg'
export const REDIRECT_TO_MEDIUM = 'https://medium.com/@blockpass'
export const REDIRECT_TO_BLOCKPASS = 'https://www.blockpass.org/'
export const REDIRECT_TO_HELP =
  'https://help.blockpass.org/hc/en-us/requests/new'

// micro
// micro-frontend
export const ADMIN_CHECK_KYC_KEY = 'checkkyc'
export const ADMIN_FLOW_CONFIG_KEY = 'flowconfig'
export const ADMIN_RUNNING_FLOW_KEY = 'runningflow'
export const ADMIN_IDENTITY_KEY = 'identityfields'
export const ADMIN_IDENTITY_GROUP_KEY = 'identitygroup'
export const ADMIN_MARKET_PLACE_KEY = 'marketplace'
export const ADMIN_SMART_ENGINE_UI_KEY = 'smartengineui'
export const ADMIN_LISTING_RUNNING_CHECK_KEY = 'listingrunningcheck'
export const SERVICE_DISPLAY_KEY = 'servicedisplay'
export const SERVICE_BILLING_KEY = 'servicebillingv2'
export const SERVICE_WEBHOOK_KEY = 'servicewebhookv2'
export const SERVICE_TEAM_KEY = 'serviceteam'
export const SERVICE_APIKEY_KEY = 'serviceapi'
export const SERVICE_USAGE_KEY = 'serviceusage'
export const SERVICE_ADMIN_KEY = 'serviceadmin'
export const SERVICE_ADVANCE_KEY = 'serviceadvance'
export const SERVICE_MARKET_PLACE_KEY = 'servicemarketplace'
export const ADMIN_BILLING_CONSOLE_KEY = 'billingconsole'

export const KYB_SERVICE_DISPLAY_KEY = 'kybservicedisplay'

//
export const ACTIVITY_LOGS_KEY = 'activitylogs'
export const SYSTEM_LOGS_KEY = 'activitylogs'
export const COMPANY_KEY = 'company'
//
export const SERVICE_NAME_CHARACTER_LIMIT = 64
export const SERVICE_SHORT_DESCRIPTION_CHARACTER_LIMIT = 100
export const SERVICE_CLIENT_ID_CHARACTER_LIMIT = 70
//
export const DEFAULT_TEXT_BLOCKPASS = 'blockpass.org'

//
export const FULL_RIGHT = 'fullright'

export const SHAREABLE_LINK =
  'https://verify-with.blockpass.org/?clientId=%%CLIENTID%%'
export const SHAREABLE_LINK_FULL =
  'https://verify-with.blockpass.org/?clientId=%%CLIENTID%%&env=%%EVN%%'

export const SHAREABLE_LINK_KYB_QA =
  'https://qa-kyb.blockpass.org/?kybClientId=%%CLIENTID%%'
export const SHAREABLE_LINK_KYB_STAGING =
  'https://sandbox-kyb.blockpass.org/?kybClientId=%%CLIENTID%%'
export const SHAREABLE_LINK_KYB_PROD =
  'https://kyb.blockpass.org/?kybClientId=%%CLIENTID%%'
export const RESTRICTED_DOCUMENT_LINK =
  'https://docs.blockpass.org/docs/connect/KYCC-Common-Mgmt-Install-Widget#refid-string'
export const WIDGET_DOCUMENT_LINK =
  'https://docs.blockpass.org/docs/connect/KYCC-Common-Mgmt-Install-Widget'
export const APP_LINKS_AND_DEEP_LINKS =
  'https://docs.blockpass.org/docs/connect/KYCC-Console-Service-AppLink'
export const BP_SALE_EMAIL = 'sales@blockpass.org'
