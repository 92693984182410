import { observer } from 'mobx-react-lite'

import useRootStore from '@hooks/useRootStore'
import { AppContainer } from './Container.styled'
import { useTutorial } from '@/hooks/useTutorial'

export const Container = observer(function Container(props: any) {
  const rootStore = useRootStore()
  const {
    UILayoutStore: {
      appBar: { visible: appBarVisible, dense: appBarDense },
      fullscreen,
    },
  } = rootStore
  useTutorial()

  return (
    <AppContainer
      {...props}
      appBarVisible={appBarVisible}
      appBarDense={appBarDense}
      fullscreen={fullscreen}
    />
  )
})

export default Container
