import ServiceDisplay from '../ServiceDisplay/ServiceDisplay'
import ServiceWebhook from '../ServiceWebhook/ServiceWebhook'
import ServiceWebhookLog from '../ServiceWebhookLog/ServiceWebhookLog'
import ServiceBilling from '../ServiceBilling/ServiceBilling'
import ServiceTeam from '../ServiceTeam/ServiceTeam'
import ServiceApiKey from '../ServiceApiKey/ServiceApiKey'
import ServiceUsage from '../ServiceUsage/ServiceUsage'
import ServiceMarketplace from '../ServiceMarketplace/ServiceMarketplace'
import ServiceAdmin from '../ServiceAdmin/ServiceAdmin'
import ServiceAdvance from '../ServiceAdvance/ServiceAdvance'

import KybServiceDisplay from '../KybServiceDisplay/KybServiceDisplay'
export const serviceDetails = {
  display: {
    key: 'display',
    component: <ServiceDisplay />,
  },
  plan: {
    key: 'plan',
    component: <ServiceBilling />,
  },
  team: {
    key: 'team',
    component: <ServiceTeam />,
  },
  apikey: {
    key: 'apikey',
    component: <ServiceApiKey />,
  },
  webhooksettings: {
    key: 'webhooksettings',
    component: <ServiceWebhook tabValue={0} />,
  },
  webhooklogs: {
    key: 'webhooklogs',
    component: <ServiceWebhookLog tabValue={1} />,
  },
  usage: {
    key: 'usage',
    component: <ServiceUsage />,
  },
  marketplace: {
    key: 'marketplace',
    component: <ServiceMarketplace />,
  },
  admin: {
    key: 'serviceadmin',
    component: <ServiceAdmin />,
  },
  advance: {
    key: 'serviceadvance',
    component: <ServiceAdvance />,
  },
  kybdisplay: {
    key: 'kybdisplay',
    component: <KybServiceDisplay />,
  },
  kybteam: {
    key: 'kybteam',
    component: <ServiceTeam />,
  },
}
