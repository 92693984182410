/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "您可以在入职期间向用户请求更多信息。这些属性未经 Blockpass 验证。",
  "txt2FA": "2FA",
  "txt404": "找不到您要查找的页面！",
  "txtAML": "反洗钱",
  "txtAMLCheck": "反洗钱检查",
  "txtAMLDesc1": "根据 PEP/制裁名单和不良媒体的全球数据库进行筛选，并确定您的客户是否受到政治曝光、受到制裁或拥有不良媒体形象。",
  "txtAMLDesc2": "此检查要求用户提供全名和出生日期。",
  "txtAPIAndWebhooks": "API 和网络钩子",
  "txtAccountDeletion": "帐户删除",
  "txtAccountDeletionDesc": "删除移动和网络帐户",
  "txtAction": "行动",
  "txtActive": "积极的",
  "txtActivityLogs": "活动日志",
  "txtAdd": "添加",
  "txtAddCompany": "添加公司",
  "txtAddMember": "添加会员",
  "txtAddMemberDesc": "会员将收到一封电子邮件，允许其访问此服务。",
  "txtAddMemberEmailIsRequired": "请输入您会员的电子邮件。",
  "txtAddMembers": "添加成员",
  "txtAddNewBpOperatorToService": "分配",
  "txtAddNewCompany": "添加新公司",
  "txtAddNewOperatorSuccess": "添加新操作员成功。",
  "txtAddNewsContent": "添加新闻",
  "txtAddNotification": "添加通知",
  "txtAddOperatorToService": "将操作员添加到服务中",
  "txtAddPaymentMethod": "添加付款方式",
  "txtAddPaymentMethodFail": "这里出了点问题。请稍后添加付款方式。",
  "txtAddPaymentMethodSuccessfully": "您的支付卡已成功添加",
  "txtAddRichContent": "添加丰富内容",
  "txtAddTag": "添加标签",
  "txtAddTagPlaceholder": "输入标签的标签...",
  "txtAddTagSuccess": "“<0>”标签添加成功。",
  "txtAddingTag": "添加标签 ",
  "txtAdmin": "行政",
  "txtAdminConsole": "管理控制台",
  "txtAdminSettingsUpdateSuccess": "管理设置更新成功。",
  "txtAdministration": "行政",
  "txtAdvanced": "先进的",
  "txtAdvancedApprovalDesc": "高级 KYC 机器人： ",
  "txtAdvancedApprovalDesc1": "用于自动批准或拒绝用户的 10 多个规则集。",
  "txtAdvancedApprovalTooltip": "高级 KYC 机器人不适用于此计划。如果您想启用它，请联系我们。",
  "txtAfterReturn": "API返回数据后 ",
  "txtAgeRestriction": "年龄限制",
  "txtAgeRestrictionDesc1": "您可以根据年龄排除申请人。 ",
  "txtAgeRestrictionDesc2": "申请人必须至少",
  "txtAgeRestrictionDesc3": "岁了。",
  "txtAllPlanFeatureHeader": "所有计划均包含以下功能",
  "txtAllTimeAreUTC": "（所有时间均为UTC）",
  "txtAllowed": "允许",
  "txtAllowedCountries": "允许的国家",
  "txtAlreadyMember": "该用户已经是该服务的会员。",
  "txtAnnouncements": "公告",
  "txtAnyoneWithTheLink": "任何有链接的人",
  "txtAnyoneWithTheLinkDesc1": "知道此链接的任何人都可以注册您的服务。",
  "txtAnyoneWithTheLinkDesc2": "此页面允许申请人将其 KYC 信息发送到您的仪表板。将链接复制并粘贴到电子邮件或任何您想要共享的位置。",
  "txtApiKey": "API密钥",
  "txtApiKeyCopied": "您的 API 密钥已复制",
  "txtApiKeyDeleteDesc": "一旦删除，API 密钥将无法恢复。如果您不完全确定该密钥是否可以删除，您可以将状态设置为非活动状态。",
  "txtApiKeys": "API 密钥",
  "txtApply": " 申请。",
  "txtApproveAdvanced": "高级 KYC 机器人",
  "txtApproveBasic": "KYC 机器人",
  "txtApproveDesc1": "您可以通过选择审核模式来节省时间。 \n",
  "txtApproveDesc2": "建议大量用户使用 KYC 机器人。看 ",
  "txtApproveDesc3": "更多细节",
  "txtApproveDesc4": " 在文档中",
  "txtApproveFreeManual": "手动：手动批准或拒绝每个用户。选择一个计划来加快您的入职流程。",
  "txtApproveNone": "人工审核",
  "txtApproverTooltipkyc": "可以审核和批准 KYC。无法删除配置文件或更新设置",
  "txtApproverkyc": "KYC 审批人",
  "txtAssignCompanyToService": "添加公司信息",
  "txtAssignRole": "分配的角色",
  "txtAssignUserRole": "分配的角色",
  "txtAttributeDataDesc": "配置文件归档后，仅保留元数据，身份属性将被删除。",
  "txtAttributesConfig": "属性配置",
  "txtAttributesConfigDesc": "配置用户配置文件中可用的属性",
  "txtAttributesGroupConfig": "属性组配置",
  "txtAttributesGroupConfigDesc": "配置用户配置文件中可用的属性组",
  "txtAutoApproval": "自动审批",
  "txtAutoArchive": "自动存档用户的个人资料",
  "txtAutoArchiveTooltip": "API 返回数据后几秒自动存档用户的个人资料 @@ar_ch_ti_me@@",
  "txtAutoTranslate": "（自动翻译）",
  "txtAutomationRules": "自动化规则",
  "txtAvatar": "阿凡达",
  "txtAvaxAddress": "雪崩地址",
  "txtBack": "后退",
  "txtBasicApprovalDesc": "KYC 机器人：  ",
  "txtBasicApprovalDesc1": "通过明确的检查自动批准用户。",
  "txtBasicInfo": "基本信息",
  "txtBestPractices": "最佳实践",
  "txtBilling": "计费",
  "txtBillingInfo": "账单信息",
  "txtBillingInfomation": "账单信息",
  "txtBillingOwner": "帐单所有者",
  "txtBinanceAddress": "币安（BSC）地址",
  "txtBitcoinAddress": "比特币地址",
  "txtBlockpass": "Blockpass",
  "txtBlockpassAccess": "块通访问",
  "txtBlockpassAccessDesc": "允许 Blockpass 支持团队访问您的 KYC 仪表板",
  "txtBlockpassAccessPermission": "您无权访问此功能",
  "txtBlockpassKycRules": "Blockpass KYC 规则",
  "txtBpKYCRules": "Blockpass KYC 规则",
  "txtBrowse": "浏览",
  "txtBtcAdress": "比特币地址",
  "txtBusinessInfomation": "业务信息",
  "txtBusinessTerms": "商业条款",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "无法为具有多个加密字段的服务设置 onchainKyc 标签",
  "txtCancel": "取消",
  "txtCardHolderName": "持卡人姓名",
  "txtCardInfomation": "卡信息",
  "txtCardanoAddress": "卡尔达诺（ADA）地址",
  "txtCaution": "注意！",
  "txtCautionDesc1": "如果您目前正在招募用户，不建议更改计划。请联系我们寻求帮助。",
  "txtCautionDesc2": "否则，您可以继续配置。",
  "txtCertificatesOrderConfig": "证书订单配置",
  "txtCertificatesOrderConfigDesc": "在用户配置文件中配置证书顺序",
  "txtChangeBillingPlan": "更改计费计划",
  "txtChangePlan": "改变计划",
  "txtChangeWebhook": "更新了网络钩子",
  "txtChartEmptyTooltip": "当用户使用您的服务时，将填充此图表。",
  "txtCheckDebugger": "检查调试器",
  "txtCheckDebuggerDesc": "按支票 ID 搜索支票。支票通常会签发证书",
  "txtChecksConfig": "检查配置",
  "txtChecksConfigDesc": "配置定义检查的属性和证书",
  "txtChinese": "中国人",
  "txtClickOrDragToUpload": "单击或拖动以上传您的徽标",
  "txtClientId": "客户ID",
  "txtClientIdExisted": "客户端 ID 已存在，请使用其他客户端 ID",
  "txtClientIdTooltip": "您的服务的唯一标识符。 \n它是根据自动生成的\n在您的服务名称上",
  "txtClose": "关闭",
  "txtCodeHasBeenCopied": "代码已被复制",
  "txtCodeSample": "代码示例",
  "txtCompany": "公司",
  "txtCompanyAddress": "公司地址",
  "txtCompanyAddressCity": "城市",
  "txtCompanyAddressCountry": "国家",
  "txtCompanyAddressLine1": "地址第一行",
  "txtCompanyAddressLine1IsRequired": "公司地址第 1 行是必需的。",
  "txtCompanyAddressLine2": "地址行 2（可选）",
  "txtCompanyAddressPostalCode": "邮政编码",
  "txtCompanyAddressPostalCodeRequired": "需要邮政编码。",
  "txtCompanyAddressState": "状态",
  "txtCompanyCity": "城市",
  "txtCompanyCountryIsRequired": "国家/地区为必填项",
  "txtCompanyCustomRequest": "信息",
  "txtCompanyEmailIsRequired": "需要公司电子邮件。",
  "txtCompanyGroup": "3. 公司",
  "txtCompanyInfo": "公司信息",
  "txtCompanyInfomation": "公司信息",
  "txtCompanyName": "公司名称",
  "txtCompanyNameIsRequired": "公司名称为必填项",
  "txtCompanyNameRepIsRequired": "公司代表姓名为必填项。",
  "txtCompanyNumber": "公司号码",
  "txtCompanyNumberIsRequired": "公司号码为必填项",
  "txtCompanyPhoneRepIsRequired": "需要公司代表的电话。",
  "txtCompanyPostalCode": "邮政编码",
  "txtCompanyRepresentative": "公司代表姓名",
  "txtCompanyState": "状态",
  "txtCompanyVAT": "税号或增值税号",
  "txtCompanyVATNumber": "税号或增值税号",
  "txtCompanyVATNumberIsRequired": "需要公司增值税号",
  "txtConfiguration": "配置",
  "txtConfirm": "确认",
  "txtConfirmDeleteCompanyInfo": "如果您返回上一步，所有输入的公司信息都将被删除。",
  "txtConnectWithUs": "与我们联系",
  "txtContactSale": "联系销售人员",
  "txtContactSaleTeam": "联系我们的销售团队",
  "txtContactSupport": "联系销售人员",
  "txtContent": "内容",
  "txtContinue": "继续",
  "txtContinueToConfigureService": "继续配置您的服务",
  "txtCopied": "复制了！",
  "txtCopy": "复制",
  "txtCopyAndClose": "复制并关闭",
  "txtCopyApiKeyWarning": "关闭弹出窗口后您将无法检索它。将其存放在安全的地方。 \n如果丢失，您将必须生成一个新密钥。",
  "txtCopyLink": "复制链接",
  "txtCopyright": "© 布洛克通行证 ",
  "txtCount": "数数",
  "txtCountryRestriction": "国家/地区限制",
  "txtCountryRestrictionDesc1": "您可以排除某些国家/地区的居民或仅接受其他国家/地区的居民。此选项要求用户提供其居住地址。",
  "txtCountryRestrictionDesc2": "此处提供的国家/地区仅作为示例。您应该向您的合规官咨询要限制的国家/地区。",
  "txtCountryRestrictionDescAllow": "仅允许来自以下地区的居民",
  "txtCountryRestrictionDescExclude": "将居民排除在外",
  "txtCoutryOfIncorporation": "公司成立国家/地区",
  "txtCreate": "创造",
  "txtCreateAPIKey": "创建 API 密钥",
  "txtCreateAPIKeyFooter1": "公开暴露 API 密钥可能会导致用户的个人数据遭到泄露。 ",
  "txtCreateAPIKeyFooter2": "你必须遵循 ",
  "txtCreateAPIKeyFooter3": " 用于安全地使用 API 密钥。",
  "txtCreateAccount": "已创建新帐户",
  "txtCreateApiKeySuccess": "创建了新的 API 密钥",
  "txtCreateBillingPlan": "创建计费计划",
  "txtCreateCertificate": "创建了新证书",
  "txtCreateCheckConfig": "创建检查配置",
  "txtCreateCompany": "创建公司",
  "txtCreateFlowConfig": "创建流程配置",
  "txtCreateIdentityField": "创建身份字段",
  "txtCreateKYCSettings": "创建 KYC 设置",
  "txtCreateNewTag": "创建标签 ",
  "txtCreateRuntimeCfg": "创建运行时配置",
  "txtCreateService": "创建了新服务",
  "txtCreateServiceDashboard": "创建服务",
  "txtCreateServiceSuccess": "新服务创建成功",
  "txtCreateVersion": "创建了新的 API 版本",
  "txtCreateWebhook": "已创建网络钩子",
  "txtCreatedAt": "创建于",
  "txtCryptoAddresses": "加密地址",
  "txtCurrentPlan": "目前计划",
  "txtCustomRegistrationUrl": "自定义注册网址",
  "txtCustomUrlInvalid": "该网址无效。",
  "txtCustomiseAppLink": "移动应用程序链接",
  "txtDAY": "天",
  "txtDOB": "出生日期",
  "txtDashboard": "仪表板",
  "txtDataEncryption": "数据加密",
  "txtDataEncryptionDes": "上传公共 PEM 密钥以与加密存储服务一起使用。",
  "txtDataExport": "数据导出",
  "txtDate": "日期",
  "txtDelete": "删除",
  "txtDeleteAPIKey": "删除API密钥",
  "txtDeleteApiKeySuccess": "API 密钥已删除",
  "txtDeleteBulkIdentityRecordConfirmMessage": "您确定要删除这些帐户吗？",
  "txtDeleteThisApiKey": "删除此 API 密钥",
  "txtDeleteVersion": "已删除的 API 版本",
  "txtDeleteWebhook": "已删除网络钩子",
  "txtDescription": "描述",
  "txtDescriptionIsRequired": "请输入此 API 密钥的描述",
  "txtDetachPaymentMethod": "分离付款方式",
  "txtDetails": "细节",
  "txtDisabled": "残疾人",
  "txtDiscard": "丢弃",
  "txtDiscardRichContent": "放弃丰富的内容",
  "txtDisplay": "展示",
  "txtDoNotSendAddMember": "您无权邀请新成员。请联系服务所有者。错误代码 403。",
  "txtDocs": "文档",
  "txtDocumentation": "文档",
  "txtDonNotHaveService": "您在这家公司没有任何服务",
  "txtDontHavePaymentMethod": "您没有任何付款方式，请在下面添加付款方式",
  "txtDowngradeBpOperator": "降级",
  "txtDownload": "下载",
  "txtDownloadLogs": "下载日志",
  "txtDrivingLicense": "驾驶执照",
  "txtDropFileHere": "将您的徽标放在此处",
  "txtEditClientId": "编辑客户端 ID",
  "txtEditClientIdWarning": "此客户端 ID 以后无法更改，并且必须是唯一的。",
  "txtEditConfig": "编辑配置",
  "txtEditMembers": "编辑成员",
  "txtEditNewsContent": "编辑新闻",
  "txtEditSearch": "编辑搜索",
  "txtEmail": "电子邮件",
  "txtEmailAddress": "电子邮件",
  "txtEmailRepresentative": "公司代表的电子邮件",
  "txtEmailWrongFormat": "电子邮件格式错误",
  "txtEnable": "使能够",
  "txtEnableApiKeys": "启用 API 密钥",
  "txtEnableApiKeysDes": "允许客户使用 API 密钥访问用户数据。",
  "txtEnableEndUserRegistrations": "启用最终用户注册",
  "txtEnableEndUserRegistrationsDes": "允许最终用户注册此服务进行验证。",
  "txtEnableKycDashboard": "启用 KYC 仪表板",
  "txtEnableKycDashboardDes": "允许客户访问 KYC 仪表板。",
  "txtEnableService": "启用服务",
  "txtEnableServiceDes": "这个选项是危险的；频繁打开和关闭服务可能会导致严重错误。",
  "txtEnabled": "启用",
  "txtEnabled2FA": "启用 2FA",
  "txtEndDate": "结束日期",
  "txtEnglish": "英语",
  "txtEnterpriseDesc1": "每月至少 100 个配置文件",
  "txtEnterpriseDesc2": " 了解更多信息。",
  "txtEnterpriseSentDesc1": "感谢您选择企业计划。我们的销售团队将尽快与您联系并根据您的要求配置服务。 \n",
  "txtEnterpriseSentDesc2": "同时，请添加付款方式并填写您的账单信息，这是启用您的计划所必需的",
  "txtErrorUnsupportFile": "不支持的文件",
  "txtEthAddress": "以太坊地址",
  "txtEthereumAddress": "以太坊地址",
  "txtEvent": "事件",
  "txtExample": "例子",
  "txtExcept": "除了",
  "txtExcluded": "排除",
  "txtExcludedCountries": "被排除在外的国家",
  "txtExpiryDate": "到期日",
  "txtExploreKyc": "探索您的 KYC Connect 仪表板。 ",
  "txtExportServiceConfig": "导出服务配置",
  "txtFaceMatch": "脸部匹配",
  "txtFaceMatchCheck": "人脸匹配检查",
  "txtFaceMatchDesc1": "Blockpass 将用户 ID 上的照片与注册时请求的实时自拍照进行比较。",
  "txtFaceMatchDesc2": "此检查要求用户提供身份证明文件和实时自拍照。",
  "txtFacematchCheck": "面部匹配检查",
  "txtFailedToSendMemberInvitation": "发送会员邀请失败",
  "txtFamilyName": "姓",
  "txtFaq": "常问问题",
  "txtFeatureInProgress": "功能正在进行中",
  "txtFileNotSupport": "不支持该文件",
  "txtFillCompanyInfo": "根据下面的现有公司填写信息或添加新公司。",
  "txtFlowAddress": "流（FLOW）地址",
  "txtFlowDebugger": "流程调试器",
  "txtFlowDebuggerDesc": "按流 ID 搜索流。流程可以包括多项检查",
  "txtFlowsConfig": "流程配置",
  "txtFlowsConfigDesc": "配置定义流程的检查",
  "txtForbidden": "您没有执行此操作的权限",
  "txtForceAddOperator": "将操作员添加到服务中",
  "txtForceAddOperatorDesc": "服务所有者不允许 Blockpass，请仔细检查您是否有权分配操作员",
  "txtFreePlanDescription": "您收到未经验证的身份。每天限量 10 个。",
  "txtFreeTrial": "免费试用",
  "txtFrench": "法语",
  "txtFunctionUnavailable": "功能暂时无法使用",
  "txtGeneralInfomation": "一般信息",
  "txtGeoRestriction": "地理限制",
  "txtGetServiceDisplayFailed": "获取服务显示失败。",
  "txtGetServiceListFailed": "获取服务列表失败。",
  "txtGetServiceWebhookListFailed": "获取服务 Webhook 失败。",
  "txtGivenName": "给定的名称",
  "txtGoToDashboard": "转到仪表板",
  "txtGoToServiceDashboard": "转到服务仪表板",
  "txtGoToServiceList": "之后",
  "txtGotIt": "知道了",
  "txtGotoDashboardDesc1": "转至此服务的 KYC Connect 仪表板。",
  "txtGotoDashboardDesc2": "您的团队可以在仪表板中查看个人上传到您的服务的身份。",
  "txtHelp": "帮助",
  "txtIdEngine": "身份识别引擎",
  "txtIdEngineDesc": "测试ID引擎OCR工具",
  "txtIdentityDesc1": "Blockpass 可验证护照、国民身份证和驾驶执照。 ",
  "txtIdentityDesc2": "要求用户拍摄其文档的实时照片。",
  "txtIdentityDesc3": "此选项要求用户提供身份证明文件、全名和出生日期。",
  "txtIdentityDocument": "身份证明文件",
  "txtIdentityDocumentCheck": "身份证件检查",
  "txtIdentityLink1": "查看支持的国家/地区列表",
  "txtImageTooBig": "图片太大",
  "txtImgDimensionBetween10242048": "请上传 512x512 和 2048x2048 像素之间的图片",
  "txtImgDimensionRule": "PNG、JPEG。最小 512x512 像素，最大 2048x2048 像素。",
  "txtImpactChanging": "冲击充电",
  "txtInactive": "不活跃",
  "txtIncludeRawDataExceptPictureTooltip": "返回除图片之外的元数据和身份属性",
  "txtIncludeRawDataIncludePictureTooltip": "返回元数据和身份属性，包括图片",
  "txtIncludeRawDataTooltip": "返回除图片之外的元数据和身份属性",
  "txtIncluding": "包括",
  "txtIncorrectFormat": "字段应仅包含普通/大写字符、数字和下划线 (_)",
  "txtInfomation": "信息",
  "txtInputYourServiceName": "输入您的服务名称",
  "txtInternalServerError": "服务器发生意外错误",
  "txtInvalidEmailFormat": "电子邮件格式无效",
  "txtInvalidEmails": "无效的电子邮件",
  "txtInvalidParams": "请求包含无效参数，请重新检查您的输入",
  "txtInvalidURL": "网址无效",
  "txtInvalidUrl": "该网址无效",
  "txtInvitationRemoved": "邀请已被删除",
  "txtInvitationResent": "邀请已重新发送",
  "txtInvitationSent": "邀请已发送至",
  "txtInviteAlreadySent": "邀请已发送",
  "txtInviteUser": "受邀用户",
  "txtIsCopiedToClipboard": "被复制到剪贴板。",
  "txtIsSuccess": "成功",
  "txtJapanese": "日本人",
  "txtJobId": "职位编号",
  "txtKYCBot": "KYC 机器人",
  "txtKYCDashboardAccess": "KYC 仪表板访问",
  "txtKybDashboard": "KYB 仪表板",
  "txtKybOnboardingContent": "您现在可以在此处查看和管理您的 KYB 服务。",
  "txtKybOnboardingTitle": "新的！ KYB服务来了👋",
  "txtKybService": "KYB服务",
  "txtKybServiceDesc": "KYB 代表“了解您的业务”。创建 KYB 服务来验证企业身份。",
  "txtKybServiceName": "KYB 服务名称",
  "txtKybServiceNonBlockpassWarning": "此功能仅适用于企业客户。请联系您的 Blockpass 客户经理或<0>。",
  "txtKybServices": "KYB服务",
  "txtKycDashboard": "KYC 仪表板",
  "txtKycService": "了解您的客户服务",
  "txtKycServiceDesc": "KYC 代表“了解您的客户”。创建 KYC 服务来验证个人身份。",
  "txtKycServiceName": "KYC 服务名称",
  "txtKycServices": "了解您的客户服务",
  "txtLast180Days": "过去 180 天",
  "txtLast30Days": "过去 30 天",
  "txtLast7Days": "过去 7 天",
  "txtLast90Days": "过去 90 天",
  "txtLastMonth": "上个月",
  "txtLastRunAt": "最后一次运行于",
  "txtLatestInvoice": "最新发票",
  "txtLatestInvoiceDesc": "发票每月发送至帐单所有者电子邮件地址。您还可以在下面下载最新开具的发票。",
  "txtLearnMoreAboutApiKey": "了解有关 Blockpass API 的更多信息 ",
  "txtLengthTooShort": "字段长度太短",
  "txtLink": "关联",
  "txtListDeleteEmail": "您要删除的电子邮件列表，以逗号分隔，最多 50 封电子邮件",
  "txtListOfServices": "服务清单",
  "txtListingRunningCheck": "清单运行检查",
  "txtListingRunningCheckDesc": "列出运行检查说明",
  "txtLive": "居住",
  "txtLockUser": "锁定用户",
  "txtLogin": "登录",
  "txtLogo": "标识",
  "txtLogoDesc": "PNG、JPEG、JPG。 \n最小 512x512 像素，最大 2048x2048 像素",
  "txtLogoRestriction": "图像大小必须介于 1024 x 1024 和 2048 x 2048 像素之间",
  "txtLogoSizeError": "您刚刚上传的徽标尺寸不正确。",
  "txtLogout": "退出",
  "txtLogs": "日志",
  "txtLongDescription": "详细描述",
  "txtLongDescriptionTooltip": "您的服务的完整描述（仅限无格式文本）",
  "txtMONTH": "月",
  "txtMarkerPlace": "市场",
  "txtMarkerPlaceDesc": "管理市场条目",
  "txtMarketPlace": "市场 - WIP",
  "txtMarketplace": "市场",
  "txtMarketplaceSettingUpdateSuccess": "市场设置已成功更新。",
  "txtMaticAddress": "多边形 (Matic) 地址",
  "txtMaxEmailsExceeded": "每次删除最多 50 封电子邮件",
  "txtMemberInfomation": "会员信息",
  "txtMemberRemoved": "已被删除",
  "txtMetaDataOnlyTooltip": "仅返回元数据（配置文件状态、ID...）",
  "txtMore": "更多的",
  "txtMoreDetails": "更多详情",
  "txtMoreInfomation": "更多信息",
  "txtName": "姓名",
  "txtNameOfCompany": "公司名称",
  "txtNameOfCompanyIsRequired": "公司名称为必填项",
  "txtNameOnCard": "卡上的姓名",
  "txtNameOnCardIncomplete": "卡上的姓名不完整。",
  "txtNameRepresentative": "公司代表姓名",
  "txtNationalID": "国民身份证",
  "txtNationalId": "国民身份证",
  "txtNewCompany": "新公司",
  "txtNewsContent": "新闻内容",
  "txtNext": "下一个",
  "txtNoActivityLogs": "没有符合您搜索条件的活动",
  "txtNoActivityLogsDesc": "尝试不太具体或使用不同的关键字。",
  "txtNoApiKeyDesc": "提取个人身份（包括元数据、个人信息和图像）需要 API 密钥。 ",
  "txtNoApiKeys": "没有 API 密钥",
  "txtNoCompanyAdd": "您还没有添加任何公司信息",
  "txtNoCompanyAddDesc": "单击下面添加第一个公司信息。",
  "txtNoCountryRestriction": "没有国家。使用搜索将国家/地区添加到列表中",
  "txtNoData": "未找到记录，请尝试扩大您的查询范围！",
  "txtNoDataMemberInService": "未添加运营商，请先添加运营商至此服务！ ",
  "txtNoFieldsAreUpdated": "没有更新任何字段",
  "txtNoInvoiceDesc1": "发票每月发送至帐单所有者电子邮件地址。 ",
  "txtNoInvoiceDesc2": "现在没有开具的发票可供下载。",
  "txtNoServiceMatch": "没有符合您搜索的服务",
  "txtNoServiceMatchDesc": "尝试不太具体或使用不同的关键字。",
  "txtNoSetupCost": "无安装成本",
  "txtNoUsageDetail": "无数据可显示",
  "txtNoUsageDetailDesc": "目前没有可用的统计数据。",
  "txtNone": "没有任何",
  "txtNoneApprovalDesc": "手动的： ",
  "txtNoneApprovalDesc1": "手动批准或拒绝每个用户。",
  "txtNormalDesc1": "每月最多 100 个配置文件。",
  "txtNotStoreCard": "Blockpass 不会存储您的卡详细信息。",
  "txtNotifications": "通知",
  "txtNotificationsDesc": "向移动用户发送推送通知",
  "txtOnboarding": "入职",
  "txtOnboardingGroup": "2. 入职",
  "txtOnboardingSetting": "入职设置",
  "txtOnly": " 仅有的 ",
  "txtOpenDashboard": "打开仪表板",
  "txtOperatorAssignment": "操作员分配",
  "txtOperatorAssignmentKYC": "操作员分配",
  "txtOperatorList": "运营商列表",
  "txtOverview": "概述",
  "txtPOA": "地址证明",
  "txtPage403Desc1": "您无权\n 访问该服务",
  "txtPage403Desc2": "请返回服务并找到合适的服务或联系服务所有者。",
  "txtParametersValidationError": "请求包含无效参数，请重新检查您的输入",
  "txtPassport": "护照",
  "txtPassword": "密码",
  "txtPayAsYouGo": "随用随付",
  "txtPaymentMethod": "付款方式",
  "txtPaymentMethodDeleted": "付款方式无法检索或已删除。 ",
  "txtPermissionDenied": "您没有执行此操作的权限",
  "txtPersonalAtt": "个人属性",
  "txtPersonalAttributes": "个人属性",
  "txtPhoneNumber": "电话号码",
  "txtPhoneNumberWrongFormat": "电话号码必须是数字",
  "txtPhoneRepresentative": "公司代表电话号码",
  "txtPickYourServiceName": "为您的服务选择一个名称",
  "txtPlan": "计划",
  "txtPlanConfig": "计划配置",
  "txtPlanConfigDesc": "向人们发送账单要求他们支付所欠款项的过程",
  "txtPlanDescription1": "每个企业每天的身份数量少于@@num_ident@@。\n",
  "txtPlanDescription2": "处理时间超过24小时。标准 ",
  "txtPleaseCopyAPIKey": "请复制API密钥",
  "txtPleaseReplacePaymentMethod": "请更换付款方式。",
  "txtPleaseSelectOperator": "请选择运营商。",
  "txtPleaseSelectUser": "请选择用户。",
  "txtPostalCodeWrongFormat": "格式错误。",
  "txtPrivacy": "隐私",
  "txtPrivacyPolicy": "隐私政策",
  "txtProfile": "轮廓",
  "txtPromote": "推动",
  "txtPromoteBpOperator": "推动",
  "txtPromoteOperator": "添加操作员",
  "txtPromoteOperatorSuccess": "成功提升经营者。",
  "txtProofOfAddress": "地址证明",
  "txtProvideNameOfService": "为您的服务提供一个名称",
  "txtPublicLinkDesc": "互联网上知道该链接的任何人都可以提交他们的 <0> 个人资料。",
  "txtPublishService": "发布服务",
  "txtPushNotification": "推送通知",
  "txtRedirect": "重定向",
  "txtRemove": "消除",
  "txtRemoveAccess": "删除访问权限",
  "txtRemoveBlockpassAccess": "删除 Blockpass 访问权限",
  "txtRemoveBlockpassAccessDesc": "您确定要删除 Blockpass 访问权限吗？这将阻止 Blockpass 支持团队访问您的 KYC 仪表板。",
  "txtRemoveBpOperator": "消除",
  "txtRemoveInvitation": "删除邀请",
  "txtRemoveMember": "删除该成员？",
  "txtRemoveMemberDesc": "稍后单击上面的“添加成员”按钮重新邀请该成员。",
  "txtRemoveOperator": "删除操作符",
  "txtRemoveOperatorDesc": "您确定要删除该运算符吗？",
  "txtRemoveOperatorSuccess": "删除操作员成功。",
  "txtRemoveTagConfirmContent": "您确定要删除此标签吗？",
  "txtRemoveTagConfirmTitle": "删除“<0>”标签",
  "txtRemoveTagSuccess": "“<0>”标签已被删除。",
  "txtRemoveThisMember": "删除该成员",
  "txtRemoveUser": "已删除用户",
  "txtReplace": "代替",
  "txtReplacePaymentMethod": "更换付款方式",
  "txtReplacePaymentMethodDesc1": "新卡将用于您的所有服务 ",
  "txtReplacePaymentMethodDesc2": "请注意，付款方式的更改将影响许多使用此方式的服务 \n付款方式。",
  "txtReport": "报告",
  "txtRepresentative": "代表",
  "txtRequestAccess": "请求访问权限",
  "txtRequestEnterprise": "申请企业计划",
  "txtRequestEnterpriseSent": "已发送企业计划请求！",
  "txtRequired": "* 必需的",
  "txtRequiredOneIdentityDocument": "请选择至少一份身份证明文件",
  "txtRequirementPlaceHolder": "告诉我们更多有关您的要求和时间表的信息",
  "txtResAddress": "居住地址",
  "txtResend": "重新发送",
  "txtResidentialAddress": "居住地址",
  "txtRestricted": "受限制的",
  "txtRestrictedDesc1": "只有在您网站上经过身份验证的人才能注册。",
  "txtRestrictedDesc2": "如果您想要将用户 refId 提交到小部件，请选择此选项。",
  "txtRestrictedDesc3": "请输入您网站上安装小部件的网址。",
  "txtRestrictedDescLink": "文档中的更多信息。",
  "txtRestrictedLinkDesc1": "强制用户登录您的网站，然后才能提交其 KYC 个人资料。",
  "txtRestrictedLinkDesc2": "如果您想要将用户 refId 发送到小部件，请选择此选项。 ",
  "txtRestrictedLinkDesc3": "文档中的更多信息。",
  "txtReturnAttributeData1": "返回元数据和身份属性",
  "txtReturnAttributeData2": "图片",
  "txtReturnAttributeDataExceptPicture1": "返回元数据和身份属性",
  "txtReturnAttributeDataExceptPicture2": "图片",
  "txtReturnMetaData1": "返回",
  "txtReturnMetaData2": "元数据（配置文件状态、ID...）",
  "txtReturnToServices": "返回服务",
  "txtRevoke": "撤销",
  "txtRevokeOperator": "撤销操作员",
  "txtRevokeOperatorDesc": "您确定要撤销该操作员吗？",
  "txtRevokeOperatorSuccess": "撤销操作员成功。",
  "txtRole": "角色",
  "txtRoleUpdated": "角色已更新",
  "txtRuleArchiveTime": "1 到 9999 之间的数字",
  "txtRunningFlowConfig": "运行流程配置",
  "txtRunningFlowConfigDesc": "运行流程配置说明",
  "txtRuntimeConfig": "运行时配置",
  "txtRuntimeConfigDesc": "配置控制台和仪表板参数",
  "txtRussian": "俄语",
  "txtSAVE": "节省",
  "txtSampleCode": "示例代码",
  "txtSave": "节省",
  "txtSaveChange": "保存更改",
  "txtSaveChanges": "保存更改",
  "txtScheduleDeleteProfilesSuccess": "计划删除配置文件成功",
  "txtScheduleForBulkDeletion": "批量删除时间表",
  "txtScope": "范围",
  "txtSearchActivityLogs": "按用户名/电子邮件搜索",
  "txtSearchByEmail": "通过电子邮件搜索",
  "txtSearchByNameOrClientId": "按名称或客户 ID 搜索",
  "txtSearchCountry": "搜索国家",
  "txtSearchPlaceHolder": "搜索...",
  "txtSeconds": "秒",
  "txtSeeList": "见列表。",
  "txtSelectACryptoAddress": "选择一个加密地址",
  "txtSelectAPlan": "选择一个计划",
  "txtSelectAPlanToUnlock": "升级您的计划",
  "txtSelectCryptoAddress": "请选择一个加密地址。",
  "txtSelectIdentityAttributes": "选定的身份属性",
  "txtSelectPlan": "选择计划",
  "txtSelfie": "自拍",
  "txtSendInvite": "发送邀请",
  "txtSendInviteEmail": "邀请已发送！有效期3天",
  "txtServiceAccountant": "会计",
  "txtServiceAccountantTooltip": "只能在管理控制台中查看“使用情况”选项卡和“计划”设置。",
  "txtServiceAllowBlockpassAccess": "允许 Blockpass 访问",
  "txtServiceBillingOwner": "计费所有者",
  "txtServiceBillingOwnerTooltip": "控制台和仪表板中的所有权限。可以更新公司信息和付款方式。",
  "txtServiceClientIdIsExisted": "客户端 ID 已存在，请使用其他客户端 ID",
  "txtServiceCompanyInfomation": "的公司信息",
  "txtServiceDetail": "服务细节",
  "txtServiceEmailIsRequired": "需要支持电子邮件",
  "txtServiceListInvalidPage": "分页值无效",
  "txtServiceLogo": "服务标志",
  "txtServiceLogoDesc": "您可以立即上传服务徽标，也可以稍后在“设置”中更新。",
  "txtServiceName": "服务名称",
  "txtServiceNameIsRequired": "服务名称为必填项",
  "txtServiceNameOrClientId": "服务名称或客户端 ID",
  "txtServiceNameOrClientIdPlaceholder": "输入服务名称或客户端 ID",
  "txtServiceNameTooltip": "为您的服务选择一个名称（最多 64 个字符）",
  "txtServiceNotFound": "未找到服务。",
  "txtServiceOwner": "经理",
  "txtServiceOwnerTooltip": "控制台和仪表板中的所有权限。",
  "txtServicePortalIsRequired": "需要支持门户",
  "txtServiceReadOnlyRole": "只读",
  "txtServiceReadOnlyRoleTooltip": "只能查看个人资料。无法处理 KYC 或更新设置",
  "txtServiceReviewerKyb": "KYB 审稿人",
  "txtServiceReviewerTooltipKyb": "只能处理KYB。无法删除配置文件或更新设置",
  "txtServiceReviewerTooltipkyc": "只能处理 KYC。无法批准、删除配置文件或更新设置",
  "txtServiceReviewerkyc": "KYC 审核员",
  "txtServiceTags": "服务标签",
  "txtServiceTagsDes": "添加或删除此服务的任何标签",
  "txtServices": "服务",
  "txtServicesUsage": "最近的服务使用情况",
  "txtServicesUsageDesc": "向人们发送账单要求他们支付所欠款项的过程",
  "txtSettings": "设置",
  "txtShareableDesc": "此页面允许申请人将其 KYC 信息发送到您的仪表板。将链接复制并粘贴到电子邮件或任何您想要共享的位置。",
  "txtShareableLink": "可分享的链接",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "缺少共享链接",
  "txtShortDescription": "简短描述",
  "txtShortDescriptionTooltip": "描述您的服务的简短文本（仅限无格式文本）",
  "txtShowLess": "显示较少",
  "txtShowMore": "显示更多",
  "txtShowRefId": "显示重新识别",
  "txtShowSession": "展示场次",
  "txtShowThisService": "显示此服务",
  "txtShowThisServiceDes": "打开或关闭此服务以将其显示在市场上。",
  "txtSolanaAddress": "Solana (SOL) 地址",
  "txtStartDate": "开始日期",
  "txtStartingAt": "开始于",
  "txtStartingAtTooltip": "无法提供更多信息。",
  "txtStatus": "地位",
  "txtStringMaxLengthError": "长度必须为 <0> 个或更少的字符",
  "txtStripeError": "验证您的账单信息时出现问题。请联系我们寻求支持。",
  "txtSubscribe": "订阅",
  "txtSubscribeTo": "订阅我们的时事通讯",
  "txtSubscribed": "已订阅",
  "txtSubscription": "订阅",
  "txtSupport": "支持",
  "txtSupportPortal": "您的支持门户网站的 URL",
  "txtSupportPortalTooltip": "您的客户服务门户网站的 URL",
  "txtSupportrEmail": "您的支持电子邮件",
  "txtSupportrEmailTooltip": "客户支持电子邮件地址。如果您不提供支持门户 URL，则使用此地址。默认地址是 support@blockpass.org",
  "txtTags": "标签",
  "txtTaxNumberWrongFormat": "格式无效。",
  "txtTeam": "团队",
  "txtTestService": "测试服务",
  "txtTheRequestWasMadeButNoResponseWasReceived": "网络错误",
  "txtThisFieldIsRequired": "此字段是必需的。",
  "txtTitleAllow": "允许的国家",
  "txtTitleExclude": "排除的国家/地区",
  "txtToGetStart": "要开始使用，请登录。",
  "txtToday": "今天",
  "txtTools": "工具",
  "txtTooltipAddressCountryRestriction": "如果启用国家/地区限制，则需要提供地址",
  "txtTooltipAddressProofOfAddress": "如果选择地址证明，则需要提供地址",
  "txtTotal": "全部的",
  "txtTotalForMonth": "当月总计 ",
  "txtTwoFaBannerDesc": "为了保护您的数据，我们现在需要双因素身份验证 (2FA)。您可以立即启用它，否则系统会要求您在下次登录时启用它。",
  "txtTwoFaBannerTitle": "使用 2FA 保护您的帐户",
  "txtTwoFaButtonEnableNow": "立即启用 2FA",
  "txtTypeSomthing": "输入一些东西...",
  "txtUSDperCheck": "美元/支票",
  "txtUnAuthorized": "会话已过期或无效，请重新登录",
  "txtUnauthorized": "会话已过期或无效，请重新登录",
  "txtUnlimittedCollaborator": "无限合作者",
  "txtUnsubscribe": "退订",
  "txtUpdate": "更新",
  "txtUpdateAPIKey": "更新 API 密钥",
  "txtUpdateApiKey": "更新 API 密钥",
  "txtUpdateApiKeySuccess": "API 密钥已更新",
  "txtUpdateApproveMode": "更新批准模式",
  "txtUpdateBillingPlanDisplay": "更新计费计划显示",
  "txtUpdateBillingPlanKycBot": "更新 KYC 机器人配置",
  "txtUpdateBillingPlanVerification": "更新 KYC 检查配置",
  "txtUpdateBpOperatorRole": "改变角色",
  "txtUpdateCertificate": "更新证书",
  "txtUpdateCheckConfig": "更新检查配置",
  "txtUpdateCompany": "更新公司",
  "txtUpdateCompanyInfoDesc": "更新公司信息。",
  "txtUpdateEnterpriseDesc1": "免费存储个人数据",
  "txtUpdateFlowConfig": "更新流程配置",
  "txtUpdateFreePlanDescription": "您收到未经验证的身份。数据保留：1周",
  "txtUpdateIdentityField": "更新身份字段",
  "txtUpdateInfo": "更新信息",
  "txtUpdateKYCSettings": "更新了 KYC 设置",
  "txtUpdateNormalDesc1": "个人数据保留：最长 4 周",
  "txtUpdateOperator": "更新算子",
  "txtUpdateOperatorSuccess": "更新运营商成功。",
  "txtUpdatePlanDescription1": "个人数据保留：最长 4 周",
  "txtUpdatePlanDescription2": "处理时间可能会有所不同。标准 ",
  "txtUpdatePlanSuccess": "成功更新服务计费计划",
  "txtUpdateRuntimeCfg": "更新了运行时配置",
  "txtUpdateService": "更新服务",
  "txtUpdateServiceSuccess": "已保存所有更改",
  "txtUpdateWebhook": "更新网络钩子",
  "txtUpdateWebhookSuccess": "网络钩子已更新",
  "txtUploadALogo": "上传徽标 ",
  "txtUploadPublicKey": "上传的公钥",
  "txtUrlPrivacyPolicy": "您的隐私政策的 URL",
  "txtUrlPrivacyPolicyTooltip": "用户注册前将显示隐私政策的链接",
  "txtUrlTermsAndConditions": "您的条款和条件的 URL",
  "txtUrlTermsAndConditionsTooltip": "用户注册前将显示指向您的条款和条件的链接",
  "txtUsage": "用法",
  "txtUsageMetrics": "使用指标",
  "txtUserDebugger": "用户调试器",
  "txtUserDebuggerDesc": "通过电子邮件或 supportId 搜索用户",
  "txtUserId": "用户身份",
  "txtUserSessionExpired": "用户会话已过期",
  "txtUserSessionExpiredDesc": "您已退出。请重新登录",
  "txtUsername": "用户名",
  "txtUsernameOrEmail": "用户名或电子邮件",
  "txtUsernameOrEmailPlaceholder": "输入用户名或电子邮件",
  "txtVATNumber": "增值税号",
  "txtVerification": "验证",
  "txtVerificationGroup": "1. 验证",
  "txtVerifications": "验证",
  "txtViewDetails": "查看详情",
  "txtVisitThePage": "访问页面",
  "txtWEEK": "星期",
  "txtWarningApprovalMode": "请确认审批方式后再进行下一步",
  "txtWavesAddress": "挥手地址",
  "txtWebhooks": "网络钩子",
  "txtWebsite": "您网站的网址",
  "txtWebsiteIntegration": "小部件集成",
  "txtWebsiteTooltip": "链接到您的官方网站",
  "txtWebsiteUrlIsRequired": "请填写此字段",
  "txtWelcomeBody": "创建、配置和监控服务以验证客户的身份。",
  "txtWelcomeTitle": "欢迎使用 Blockpass 管理控制台",
  "txtWelcomeTo": "欢迎来到",
  "txtWhoCanResgister": "谁可以注册？",
  "txtWillBeDeleted": "将被删除。 ",
  "txtWillRemoved": "将被删除。",
  "txtYEAR": "年",
  "txtYesterday": "昨天",
  "txtYourApiKey": "您的 API 密钥",
  "txtYourCard": "你的卡",
  "txtYourCompanyInfo": "您的公司信息",
  "txtYourEmailAddress": "您的电子邮件地址",
  "txtYourLink": "你的链接",
  "txtYourLinkCopiedToClipboard": "您的链接已复制到剪贴板",
  "txtYourPaymentMethod": "您的付款方式",
  "txtYourPaymentMethodGroup": "4. 付款方式",
  "txtYourServiceConfigCopiedToClipboard": "您的服务配置已复制到剪贴板",
  "txtYourServiceCreated": "您的服务已创建",
  "txtYourServiceLink": "您的服务链接",
  "txtYourWebsite": "https://your-website.com",
  "txtYourWebsiteUrl": "您的网站网址",
  "txtYourWebsiteUrlDesc": "请输入您安装该小部件的网站 URL",
  "txtdelete": "删除",
  "txtdonwloadMonitorReport": "下载监测报告",
  "txtsettingUpdated": "设置已更新"
} // eslint-disable-line no-template-curly-in-string