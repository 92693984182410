import { observable, action, makeObservable } from 'mobx'
import BaseStore from '../base/BaseStore'
import RootStore from '../RootStore'
import {
  ITutorial,
  TUTORIAL_PLACEMENT_MAP,
  TUTORIAL_TARGET_MAP,
  TUTORIAL_TRIGGER_DELAY,
  TUTORIALS,
} from '../../constants/tutorial'
import isEmpty from 'lodash/isEmpty'
import { CallBackProps, Step } from 'react-joyride'
import { waitMs } from '@/utils'

class UITutorialStore extends BaseStore {
  static BASE_STORE_DEBUG_COLOR = 'Blue'
  _rootStore: RootStore
  loading: boolean
  run: boolean
  tutorials: ITutorial | Record<string, never>
  version: number
  activeTutorial: keyof ITutorial | ''
  activeTutorialSteps: Step[]
  callback?: (data: CallBackProps) => void

  constructor(_rootStore: RootStore) {
    super()

    makeObservable(this, {
      activeTutorial: observable,
      activeTutorialSteps: observable,
      callback: observable,
      loading: observable,
      run: observable,
      version: observable,
      setActiveTutorial: action,
      setActiveTutorialSteps: action,
      setCallback: action,
      setLoading: action,
      setRun: action,
      setVersion: action,
    })

    this._rootStore = _rootStore
    this.loading = false
    this.run = false
    this.tutorials = TUTORIALS
    this.activeTutorial = ''
    this.activeTutorialSteps = []
    this.version = 1
  }

  onFinishTutorial() {
    if (!this.activeTutorial) return
    this.tutorials[this.activeTutorial].finished = true
    this.setRun(false)
    this.updateTutorial()
  }

  setActiveTutorial(value: keyof ITutorial) {
    this.activeTutorial = value
  }

  setActiveTutorialSteps(tutorial: Step[]) {
    this.activeTutorialSteps = tutorial
  }

  setCallback(cb: (data: CallBackProps) => void) {
    this.callback = cb
  }

  setLoading(value: boolean) {
    this.loading = value
  }

  setRun(run: boolean) {
    this.run = run
  }

  setTutorials(tutorials: ITutorial | Record<string, never>) {
    this.tutorials = tutorials
  }

  setVersion(version: number) {
    this.version = version
  }

  async startTutorial(tutorialKey: keyof ITutorial) {
    await waitMs(TUTORIAL_TRIGGER_DELAY)
    const tutorial = this.tutorials[tutorialKey]

    if (!tutorial || tutorial.finished) return

    if (this.run && this.activeTutorial === tutorialKey) return

    const steps = tutorial.steps.map((it, index) => {
      return {
        disableBeacon: true,
        target: `[data-tutorial=${TUTORIAL_TARGET_MAP[tutorialKey]?.[index]}]`,
        title: this._rootStore.LocalizationStore.translate(it.title),
        content: this._rootStore.LocalizationStore.translate(it.content),
        placement: TUTORIAL_PLACEMENT_MAP[tutorialKey],
        hideCloseButton: tutorialKey === 'kybOnBoarding',
      } as Step
    })
    this.setActiveTutorial(tutorialKey)
    this.setActiveTutorialSteps(steps)
    this.setRun(true)
  }

  stopTutorial() {
    this.setRun(false)
  }

  async getTutorial() {
    this.setLoading(true)

    try {
      const res =
        await this._rootStore.AuthStore.consoleClient.merchantProfile.getTutorial()

      if (res && res.tutorialData && !isEmpty(res.tutorialData)) {
        this.setTutorials(res.tutorialData as any)
        this.setVersion(Number(res._version))
      }
    } catch (error) {
      this.debugLog(error)
    } finally {
      this.setLoading(false)
    }
  }

  async updateTutorial() {
    if (this.loading) return

    this.setLoading(true)

    try {
      const res =
        await this._rootStore.AuthStore.consoleClient.merchantProfile.setTutorial(
          { data: this.tutorials, _version: this.version.toString() }
        )

      if (res) {
        return res
      }
    } catch (error) {
      this.debugLog(error)
    } finally {
      this.setLoading(false)
    }
  }

  reset() {
    this.setTutorials({})
    this.updateTutorial()
  }
}

export default UITutorialStore
