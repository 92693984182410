import useRootStore from '@/hooks/useRootStore'
import { createContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Joyride, {
  ACTIONS,
  CallBackProps,
  Events,
  EVENTS,
  ORIGIN,
  STATUS,
} from 'react-joyride'
import UITutorialStore from '../stores/ui/UITutorialStore'
import { TutorialTooltip } from '../components/Tutorial/TutorialTooltip'

export type TutorialContextType = UITutorialStore

export const UITutorialContext = createContext<TutorialContextType>(null!)

export const UITutorialProvider = observer(function UITutorialProvider() {
  const rootStore = useRootStore()
  const translate = rootStore.LocalizationStore.translate
  const store = rootStore.UITutorialStore
  const run = rootStore.UITutorialStore.run
  const steps = rootStore.UITutorialStore.activeTutorialSteps
  const [stepIndex, setStepIndex] = useState(0)

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, index, type } = data

    if (action === ACTIONS.SKIP && origin === ORIGIN.KEYBOARD) {
      setStepIndex(0)
      store.onFinishTutorial()
      store.callback?.(data)
      return
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setStepIndex(0)
      store.onFinishTutorial()
      store.callback?.(data)
      return
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

      setStepIndex(nextStepIndex)
    }

    store.debugLog(
      type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type,
      data
    )
  }

  return (
    <UITutorialContext.Provider value={store}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        disableOverlay
        disableScrolling
        floaterProps={{
          options: {
            preventOverflow: {
              enabled: true,
            },
            offset: {
              enabled: true,
              fn: (data) => {
                if (data.placement === 'bottom-start') {
                  data.offsets.popper.left =
                    data.offsets.reference.left +
                    data.offsets.reference.width / 2 -
                    24
                }

                return data
              },
            },
          },
          styles: {
            arrow: {
              color: '#4388FF',
              length: 8,
              spread: 16,
            },
          },
        }}
        locale={{
          back: translate('txtBack'),
          close: translate('txtClose'),
          last: translate('txtGotIt'),
          next: translate('txtNext'),
          nextLabelWithProgress: '{step} of {steps}',
        }}
        styles={{ options: { zIndex: 9999 } }}
        run={run}
        scrollToFirstStep={false}
        steps={steps.map((it) => ({ ...it, offset: -8 }))}
        stepIndex={stepIndex}
        tooltipComponent={TutorialTooltip}
      />
    </UITutorialContext.Provider>
  )
})
