import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import useViewStore from '@hooks/useViewStore'
import ViewStore from './ServiceList.ViewStore'
import {
  ShareableIcon,
  WidgetIcon,
  DashboardPaperBlackIcon,
  DashboardPaperGreyIcon,
} from '@components/Icon/CommonIcons'
import { Typography, BpDataGrid, SearchBar, Pill } from '@blockpass-org/ui'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { EmptyGraphicIcon2 } from '@workspace/shared/Icon/CommonIcons'
import COLOR from '@theme/colors'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import useRootStore from '@hooks/useRootStore'
import { SplashPage } from '../SplashPage'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { WIDGET_DOCUMENT_LINK } from '@constants/constants'
import CircularProgress from '@mui/material/CircularProgress'
import { pushDataLayer } from '@utils/index'

import loadable from '@loadable/component'
import { TwoFAWarning } from '@/components/TwoFAWarning/TwoFAWarning'
import { PreloadMicroFrontend } from './PreloadMircoFrontend'
import { ServiceListLayout } from './ServiceListLayout'
const DashboardDialog = loadable(() => import('@/components/DashboardDialog'), {
  fallback: (
    <CircularProgress size={48} style={{ color: COLOR.commonColor.primary }} />
  ),
})
const SharebleDialogContent = loadable(
  () => import('@/components/SharebleDialogContent'),
  {
    fallback: (
      <Box style={{ width: 600, height: 234, position: 'relative' }}>
        <CircularProgress
          size={48}
          style={{
            color: COLOR.commonColor.primary,
            margin: 0,
            position: 'absolute',
            top: '90px',
            left: '275px',
          }}
        />
      </Box>
    ),
  }
)

const QRCodeDialogContent = loadable(
  () => import('@/components/QRCodeDialogContent'),
  {
    fallback: (
      <CircularProgress
        size={24}
        style={{ color: COLOR.commonColor.primary }}
      />
    ),
  }
)
const DashboardTooltip = loadable(
  () => import('@components/DashboardTooltip/DashboardTooltip')
)

const ROW_HEIGHT = 56

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 40,
    height: 40,
  },
  iconMenu: {
    padding: '16px 0px 16px 0px',
  },
  containerEmpty: {
    display: 'flex',
    padding: '24px',
    paddingTop: '0px',
    // margin: 32,
    // marginTop: 0,
    backgroundColor: COLOR.commonColor.white,
    color: COLOR.textColor.secondary,
    borderRadius: 8,
    // height: 540,
  },
}))

const ServiceList = observer(function ServiceList() {
  const classes = useStyles()
  const rootStore = useRootStore()

  useEffect(() => {
    const timing = get(window as any, 'performance.timing', {})
    let pageloadtime = timing.loadEventStart - timing.navigationStart
    if (!DashboardStore.sentLoadingTime) {
      pushDataLayer({
        event: 'pageloadtime',
        env: 'Desktop',
        screen_name: 'Services List',
        session: 'Performance Timings',
        label: '',
        value: pageloadtime,
      })
      DashboardStore.sentLoadingTime = true
    } else {
      if (DashboardStore.timeStart !== 0) {
        let timeRouteChange = Date.now() - DashboardStore.timeStart
        pushDataLayer({
          event: 'route_change_time',
          env: 'Desktop',
          screen_name: 'Services List',
          session: 'Performance Timings',
          label: '',
          value: timeRouteChange,
        })
      }
    }

    DashboardStore.hideFooter = false
  })
  /* eslint-disable */
  const [currentClientId, setCurrentClientId] = useState('')
  const [currentServiceInfo, setCurrentServiceInfo] = useState({})
  const [openDialogShareableLink, setOpenDialogShareableLink] = useState(false)
  // const [openDialogOpenDashboard, setOpenDialogOpenDashboard] = useState(false);
  const [openDialogTestService, setOpenDialogTestService] = useState(false)
  /* eslint-enable */

  const {
    LocalizationStore: { translate },
    DashboardStore,
    RouteStore,
    AuthStore,
  } = rootStore
  const viewStore = useViewStore(rootStore, ViewStore, {})
  const { isKYBService } = DashboardStore
  const columns = [
    {
      field: 'logo',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {translate('txtLogo')}
        </Typography>
      ),
      width: 112,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <img
              src={params.row.thumbnail}
              style={{ width: 44, height: 44 }}
              alt={'logo'}
            />
          </>
        )
      },
    },
    {
      field: 'name',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {isKYBService
            ? translate('txtKybServiceName')
            : translate('txtKycServiceName')}
        </Typography>
      ),
      flex: 2.8,
      // minWidth: 300,
      headerAlign: 'left',
      align: 'left',
      renderHeader: (params) => {
        const { colDef } = params
        return (
          <>
            {/* <DashboardTooltip title={colDef.headerName || colDef.field} placement="bottom" arrow> */}
            {colDef.headerName || colDef.field}
            {/* </DashboardTooltip> */}
          </>
        )
      },
    },
    {
      field: isKYBService ? 'clientId' : 'client_id',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {translate('txtClientId')}
        </Typography>
      ),
      flex: 2.8,
      // minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      renderHeader: (params) => {
        const { colDef } = params

        return (
          <>
            {/* <DashboardTooltip title={colDef.headerName || colDef.field} placement="bottom" arrow> */}
            {colDef.headerName || colDef.field}

            {/* </DashboardTooltip> */}
          </>
        )
      },
    },
    {
      field: 'tags',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {translate('txtServiceTags')}
        </Typography>
      ),
      flex: 1.7,
      headerAlign: 'left',
      sortable: false,
      hide: !AuthStore.hasPermission(['admin', 'operator', 'bpReadOnly']),
      renderCell: (params) => {
        const numTags = params.row.tags.length
        let display = `${numTags} ${translate('txtTags')}`
        if (numTags <= 0) {
          display = ''
        }
        const label = params.row.tags.join(', ')
        return (
          <div style={{ whiteSpace: 'normal', overflow: 'auto' }}>
            {
              <DashboardTooltip title={label} placement="bottom" arrow>
                <div>{display}</div>
              </DashboardTooltip>
            }
          </div>
        )
      },
    },
    {
      field: 'status',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {translate('txtStatus')}
        </Typography>
      ),
      flex: 1.7,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === 'active' ? (
              <Pill
                label={params.row.status}
                type="primary"
                size={'medium'}
                style={{ textTransform: 'capitalize' }}
              />
            ) : (
              <Pill
                label={params.row.status}
                type="disabled"
                size={'medium'}
                style={{ textTransform: 'capitalize' }}
              />
            )}
          </>
        )
      },
    },
    {
      field: 'action',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {translate('txtAction')}
        </Typography>
      ),
      type: 'actions',
      flex: 1.7,
      headerAlign: 'center',
      align: 'center',
      getActions: (params) => {
        const allowRolesKYCConnect = [
          'owner',
          'billing_owner',
          'developer',
          'reviewer',
          'approver',
        ]
        const adminRole = ['admin', 'operator']
        const systemRole = AuthStore.getSystemRole()
        const serviceRole = AuthStore.getServiceRoleByClientId(
          get(
            params.row,
            DashboardStore.isKYBService ? 'clientId' : 'client_id',
            ''
          )
        )
        let canOpenKYCConnect = false
        let canOpenTestService = true

        // check service config
        if (get(params.row, 'enableDashboard', false)) {
          //role
          if (adminRole.includes(systemRole)) {
            canOpenKYCConnect = true
          } else {
            if (allowRolesKYCConnect.includes(serviceRole)) {
              canOpenKYCConnect = true
            } else {
              canOpenKYCConnect = false
            }
          }
        } else {
          canOpenKYCConnect = false
        }

        if (get(params.row, 'enableRegistration', false)) {
          canOpenTestService = true
        } else {
          canOpenTestService = false
        }

        if (DashboardStore.isKYBService) {
          return [
            <DashboardTooltip
              title={
                canOpenKYCConnect
                  ? translate('txtOpenDashboard')
                  : translate('txtFunctionUnavailable')
              }
              placement="bottom"
              arrow
            >
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={
                  canOpenKYCConnect ? (
                    <DashboardPaperBlackIcon />
                  ) : (
                    <DashboardPaperGreyIcon />
                  )
                }
                label="KYC"
                onClick={() => showDialog('showOpenDashboard', params)}
                className={classes.icon}
                disabled={!canOpenKYCConnect}
                sx={{
                  '&:hover': {
                    cursor: canOpenKYCConnect ? 'pointer' : 'not-allowed',
                    backgroundColor: canOpenKYCConnect
                      ? ''
                      : 'transparent !important',
                  },
                }}
              />
            </DashboardTooltip>,

            <DashboardTooltip
              title={translate('txtShareableLink')}
              placement="bottom"
              arrow
            >
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={<ShareableIcon />}
                label="Shareable"
                onClick={() => showDialog('showShareable', params)}
                className={classes.icon}
                // disabled={!canOpenKYCConnect}
              />
            </DashboardTooltip>,

            /*// @ts-ignore */
            <GridActionsCellItem
              icon={
                <DashboardTooltip
                  title={
                    canOpenTestService
                      ? ''
                      : translate('txtFunctionUnavailable')
                  }
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 20],
                        },
                      },
                    ],
                  }}
                  placement="left"
                  arrow
                >
                  {/* <TestServiceIcon className={classes.iconMenu} /> */}
                  <Box style={{ width: 136 }}>
                    <QRCodeDialogContent
                      data={params}
                      canOpenTestService={canOpenTestService}
                    ></QRCodeDialogContent>
                  </Box>
                </DashboardTooltip>
              }
              label={''}
              onClick={() => showDialog('showTestService', params)}
              showInMenu
            ></GridActionsCellItem>,
          ]
        } else {
          return [
            <DashboardTooltip
              title={
                canOpenKYCConnect
                  ? translate('txtOpenDashboard')
                  : translate('txtFunctionUnavailable')
              }
              placement="bottom"
              arrow
            >
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={
                  canOpenKYCConnect ? (
                    <DashboardPaperBlackIcon />
                  ) : (
                    <DashboardPaperGreyIcon />
                  )
                }
                label="KYC"
                onClick={() => showDialog('showOpenDashboard', params)}
                className={classes.icon}
                // disabled={!canOpenKYCConnect}
                sx={{
                  '&:hover': {
                    cursor: canOpenKYCConnect ? 'pointer' : 'not-allowed',
                    backgroundColor: canOpenKYCConnect
                      ? ''
                      : 'transparent !important',
                  },
                }}
              />
            </DashboardTooltip>,

            <DashboardTooltip
              title={translate('txtShareableLink')}
              placement="bottom"
              arrow
            >
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={<ShareableIcon />}
                label="Shareable"
                onClick={() => showDialog('showShareable', params)}
                className={classes.icon}
                // disabled={!canOpenKYCConnect}
              />
            </DashboardTooltip>,

            /*// @ts-ignore */
            <GridActionsCellItem
              icon={
                <DashboardTooltip
                  title={
                    canOpenTestService
                      ? ''
                      : translate('txtFunctionUnavailable')
                  }
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 20],
                        },
                      },
                    ],
                  }}
                  placement="left"
                  arrow
                >
                  {/* <TestServiceIcon className={classes.iconMenu} /> */}
                  <Box>
                    <QRCodeDialogContent
                      data={params}
                      canOpenTestService={canOpenTestService}
                    ></QRCodeDialogContent>
                  </Box>
                </DashboardTooltip>
              }
              label={''}
              onClick={() => showDialog('showTestService', params)}
              showInMenu
            ></GridActionsCellItem>,

            /*// @ts-ignore */
            <GridActionsCellItem
              icon={<WidgetIcon className={classes.iconMenu} />}
              label={translate('txtWebsiteIntegration')}
              onClick={() => showDialog('showWidget', params)}
              showInMenu
            />,
            /*// @ts-ignore */
          ]
        }
      },
    },
  ]

  const showDialog = (e, params) => {
    let clientId = ''
    if (DashboardStore.isKYBService) {
      clientId = params.row.clientId
    } else {
      clientId = params.row.client_id
    }
    setCurrentClientId(clientId)
    setCurrentServiceInfo(params.row)

    switch (e) {
      // case 'exportServiceConfig':
      //   setOpenDialogExportServiceConfig(true)
      //   break
      case 'showShareable':
        // service info
        DashboardStore.setServiceInfo(params.row)
        setOpenDialogShareableLink(true)
        break
      case 'showOpenDashboard':
        DashboardStore.setServiceInfo(params.row)
        window.open(
          DashboardStore.formpassURL(DashboardStore.getClientId()),
          '_blank'
        )

        // setOpenDialogOpenDashboard(true)
        break
      case 'showWidget':
        window.open(WIDGET_DOCUMENT_LINK, '_blank')
        break
      case 'showTestService':
        DashboardStore.setServiceInfo(params.row)
        setOpenDialogTestService(true)
        break
      default:
        break
    }
  }
  const onClose = () => {
    // setOpenDialogMobileAppLink(false)
    // setOpenDialogExportServiceConfig(false)
    // setOpenDialogOpenDashboard(false)
    setOpenDialogShareableLink(false)
    setOpenDialogTestService(false)
  }

  const onClickServiceDetail = (params) => {
    //temp service info
    // DashboardStore.setServiceInfo(params.row)
    // reset selected service
    if (DashboardStore.isCatchAllMicro()) {
      DashboardStore.setServiceInfo({})

      DashboardStore.serviceIdSelected = params.id

      if (isKYBService) {
        RouteStore.push(`/kyb/services/update/${params.id}`)
      } else {
        RouteStore.push(`/services/update/${params.id}`)
      }
    }
  }
  const handleOnSearch = async (searchString: string) => {
    if (isKYBService) {
      DashboardStore.setSearchInputKYB(searchString)
      await DashboardStore.requestListKYBService(
        0,
        DashboardStore.limitKYB,
        DashboardStore.filterKYB,
        DashboardStore.sortKYB
      )
    } else {
      DashboardStore.setSearchInput(searchString)
      await DashboardStore.requestListService(
        0,
        DashboardStore.limit,
        DashboardStore.filter,
        DashboardStore.sort
      )
    }
  }

  const { ready } = viewStore

  let loadingTable =
    DashboardStore.loadingList || !DashboardStore.isCatchAllMicro()

  if (!ready) {
    return (
      <div style={{ height: '100vh', textAlign: 'center' }}>
        <SplashPage />
      </div>
    )
  }

  const data = isKYBService
    ? DashboardStore.getKYBServiceList()
    : DashboardStore.getServiceList()
  const paging = isKYBService
    ? DashboardStore.getKYBPagingInfo()
    : DashboardStore.getPagingInfo()
  const hasRecord = !isEmpty(data)

  let defaultHeight = 125 + 76 + data.length * 56
  if (data.length === 0) {
    defaultHeight = 486
  }

  return (
    <div>
      <PreloadMicroFrontend />
      <DashboardDialog
        title={translate('txtShareableLink')}
        open={openDialogShareableLink}
        withoutTitle={false}
        onClose={onClose}
        maxWidth={'xl'}
        onBackdropClick={null}
        disableEscapeKeyDown={false}
        noDivider={false}
      >
        <SharebleDialogContent handleClose={onClose} editable={false} />
      </DashboardDialog>

      <ServiceListLayout>
        <div
          id="serviceList"
          style={{
            width: '100%',
            boxSizing: 'border-box',
            background: COLOR.commonColor.white,
            padding: '24px',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            height: defaultHeight,
            minHeight: 'calc(100vh - 365px)',
          }}
        >
          <Box>
            <SearchBar
              onSearch={handleOnSearch}
              placeHolder={translate('txtServiceName')}
              defaultValue={DashboardStore.searchInput}
              searchOnClearInput
              displayAdvanced={false}
              advanceArray={null}
            />
          </Box>
          <Box style={{ margin: '24px 0px 24px 0px' }}>
            <Divider />
          </Box>

          <BpDataGrid
            autoHeight={hasRecord ? true : false}
            separator={false}
            disableColumnMenu
            headerColor={COLOR.commonColor.greyButton}
            headerHeight={56}
            height={hasRecord ? 'auto' : '411px'}
            getRowId={(row: any) => row._id}
            columns={columns}
            rows={data}
            /*// @ts-ignore */
            rowCount={paging?.total}
            /*// @ts-ignore */
            page={paging?.page}
            paginationMode={'server'}
            hideFooter={hasRecord ? false : true}
            paginationType={'totalNumber'}
            alignPagination={'right'}
            loading={loadingTable}
            rowHeight={ROW_HEIGHT}
            optionIcon={true}
            optionTooltip={translate('txtMore')}
            // callback
            onRowClick={(params) => onClickServiceDetail(params)}
            onPageChange={async (newPage) => {
              if (isKYBService) {
                DashboardStore.updatePageKYB(newPage)
                await DashboardStore.requestListKYBService(
                  newPage,
                  DashboardStore.limitKYB,
                  DashboardStore.filterKYB,
                  DashboardStore.sortKYB
                )
              } else {
                DashboardStore.updatePage(newPage)
                await DashboardStore.requestListService(
                  newPage,
                  DashboardStore.limit,
                  DashboardStore.filter,
                  DashboardStore.sort
                )
              }
            }}
            pageSize={
              isKYBService ? DashboardStore.limitKYB : DashboardStore.limit
            }
            onPageSizeChange={async (newPageSize) => {
              if (isKYBService) {
                DashboardStore.updateLimitKYB(newPageSize)
                await DashboardStore.requestListKYBService(
                  DashboardStore.pageKYB,
                  DashboardStore.limitKYB,
                  DashboardStore.filterKYB,
                  DashboardStore.sortKYB
                )
              } else {
                DashboardStore.updateLimit(newPageSize)
                await DashboardStore.requestListService(
                  DashboardStore.page,
                  DashboardStore.limit,
                  DashboardStore.filter,
                  DashboardStore.sort
                )
              }
            }}
            onSortModelChange={async (model, detail) => {
              if (isArray(model)) {
                if (isKYBService) {
                  if (model.length > 0) {
                    DashboardStore.updateSortKYB(model[0])
                  } else {
                    // non sort -> create at
                    DashboardStore.updateSortKYB({
                      field: 'createdAt',
                      sort: 'desc',
                    })
                  }
                  await DashboardStore.requestListKYBService(
                    DashboardStore.pageKYB,
                    DashboardStore.limitKYB,
                    DashboardStore.filterKYB,
                    DashboardStore.sortKYB
                  )
                } else {
                  if (model.length > 0) {
                    DashboardStore.updateSort(model[0])
                  } else {
                    // non sort -> create at
                    DashboardStore.updateSort({
                      field: 'createdAt',
                      sort: 'desc',
                    })
                  }
                  await DashboardStore.requestListService(
                    DashboardStore.page,
                    DashboardStore.limit,
                    DashboardStore.filter,
                    DashboardStore.sort
                  )
                }
              }
            }}
            noRowTitle={translate('txtNoServiceMatch')}
            noRowDesc={translate('txtNoServiceMatchDesc')}
            emptyIcon={<EmptyGraphicIcon2 />}
          ></BpDataGrid>
        </div>
      </ServiceListLayout>
      <TwoFAWarning />
    </div>
  )
})

export default ServiceList
