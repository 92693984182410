import { useEffect } from 'react'
import useRootStore from './useRootStore'

export const useTutorial = () => {
  const rootStore = useRootStore()

  useEffect(() => {
    rootStore.UITutorialStore.getTutorial()
  }, [rootStore.UITutorialStore])
}
